@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("ratio");

[class*="#{$component}"] {
  &:before {
    display: block;
    content: " ";
    width: 100%;
  }
}

.#{$component} {
  &--1-1 {
    &:before {
      // padding-top: (1/1) * 100%;
      padding-top: calc(1 / 1 * 100%);
    }
  }

  &--38-25 {
    &:before {
      // padding-top: (25/38) * 100%;
      padding-top: calc(25 / 38 * 100%);
    }
  }

  // wide 16/9 video classic
  &--16-9 {
    &:before {
      padding-top: (9 / 16 * 100%);
    }
  }
  
  // &--lg-320-177 {
  //   @include media-breakpoint-up(lg) {
  //     &:before {
  //       padding-top: (177 / 320) * 100%;
  //     }
  //   }
  // }
}
