@import "../../assets/scss/components/base";

$component: componentClass("form");

$form-check-input-checked-bg-image-light: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$zaux-color-set1-darkblue}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-input-checked-bg-image-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$zaux-color-set1-azurewhite}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-select-arrow : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$zaux-color-set1-onyx}' class='bi bi-caret-down-fill' viewBox='0 0 16 16'> <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");

.#{$component} {
  &--light {
    & input,
    textarea,
    select {
      border: 2px solid $zaux-color-default-white;
      background-color: transparent;

      &::placeholder {
        opacity: 0.5;
        color: $zaux-color-set1-onyx;
      }
      &:focus {
        background-color: transparent;
      }
    }
    .form-check-input:checked[type="checkbox"] {
      background-image: escape-svg($form-check-input-checked-bg-image-light);
      background-color: transparent;
      border-color: $zaux-color-set1-darkblue;
    }
    & select {
      background-image: escape-svg($form-select-arrow);
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: calc(100% - 20px) center;
    }
    & select option {
      color: $zaux-color-set1-onyx;
    }
    & .searchbar {
      input {
        border: 2px solid $zaux-color-set1-azurewhite;
        &:placeholder-shown{
          background-image: url("../../assets/icon/icozaux1/SVG/search.svg");
          opacity: 0.5;
          background-repeat: no-repeat;
          background-size: 16px 16px;
          background-position: 25px center;
        }
      }
    }
  }

  &--dark {
    & label {
      color: $zaux-color-default-white;
    }
    & input:not([type="submit"]) {
      border: 2px solid $zaux-color-set1-beaublue;
      background-color: transparent;

      &::placeholder {
        opacity: 0.5;
      }

      &:focus {
        background-color: transparent;
      }
    }

    & input[type="email"] {
      color: white;
    }

    .form-check-input:checked[type="checkbox"] {
      background-image: escape-svg($form-check-input-checked-bg-image-dark);
      background-color: transparent;
      border-color: $zaux-color-set1-azurewhite;
    }
  }
  &--dark2 {
    & label {
      color: $zaux-color-default-white;
    }
    & input {
      border: 2px solid $zaux-color-set1-beaublue;
      background-color: transparent;

      &::placeholder {
        color: $zaux-color-default-white !important;
        opacity: 0.5;
      }
      &:focus {
        background-color: transparent;
      }
    }
    & input[type="email"] {
      color: white;
    }
    & input[type="checkbox"] {
      border-color: $zaux-color-set1-beaublue !important;
    }
    .form-check-input:checked[type="checkbox"] {
      background-image: escape-svg($form-check-input-checked-bg-image-dark);
      background-color: transparent;
      border-color: $zaux-color-set1-azurewhite;
    }
  }
}
