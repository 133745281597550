@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("btn");

// Homepage Desktop: 300px 70px   Homepage Mobile: 200px 50px
// Menu cat Desktop: 320px 50px   Menu cat Mobile: 310px 50px
// Share single page: 70px 70px  Mobile: 50px 50px

$zaux-btn-size: 200px;
$zaux-btn-size-icon: 230px;
$zaux-btn-size-lg: 300px;

$zaux-btn-cat: 310px;
$zaux-btn-cat-lg: 320px;

$zaux-btn-share: 50px;
$zaux-btn-share-lg: 70px;

$zaux-btn-footer: 100px;

$zaux-btn-height1: 70px;
$zaux-btn-height2: 50px;
$zaux-btn-height3: 40px;

a[class*="#{$component}"] {
  display: inline-block;
  text-decoration: none;
}

[class*="#{$component}"][class*="--size"] {
  text-align: center;
}

.#{$component} {
  &1 {
    width: 100%;
    font-family: $zaux-font2;
    font-weight: $zaux-typo-fw-medium;
    border-radius: $border-radius-lg;

    &--sm {
      line-height: rem(16px);
      font-size: rem(16px);
      padding: map-get($spacers, "3");
      @include media-breakpoint-up(xlm) {
        line-height: rem(27px);
        font-size: rem(20px);
        // padding: map-get($spacers, "4");
        padding: 1.35rem;
      }
    }

    &--ct {
      line-height: rem(21px);
      font-size: rem(16px);
      padding: map-get($spacers, "3");
    }

    &--footer {
      line-height: rem(16px);
      font-size: rem(13px);
      padding: map-get($spacers, "3");
      width: 70%
    }

    &--share {
      padding: rem(13px);
      @include media-breakpoint-up(xlm) {
        padding: map-get($spacers, "4");
      }
    }

    &--header {
      padding: rem(13px);
    }

    &--size-l {
      min-width: $zaux-btn-size;
      width: auto;
      // max-height: $zaux-btn-height2;
      @include media-breakpoint-up(xlm) {
        min-width: $zaux-btn-size-lg;
        // max-height: $zaux-btn-height1;
      }
    }

    &--size-2 {
      max-width: $zaux-btn-cat;
      // max-height: $zaux-btn-height2;
      @include media-breakpoint-up(xlm) {
        max-width: $zaux-btn-cat-lg;
      }
    }

    &--size-3 {
      max-width: $zaux-btn-footer;
      // max-height: $zaux-btn-height2;
    }

    &--size-4 {
      max-width: $zaux-btn-share;
      // max-height: $zaux-btn-height2;
      @include media-breakpoint-up(xlm) {
        max-width: $zaux-btn-share-lg;
        // max-height: $zaux-btn-height1;
      }
    }
    &--size-5{
      max-width: $zaux-btn-size-icon;
      // max-height: $zaux-btn-height2;
      @include media-breakpoint-up(xlm) {
        max-width: $zaux-btn-size-lg;
        // max-height: $zaux-btn-height1;
      }
    }

    &--size-6{
      max-width: $zaux-btn-share;
      }


  }

  &2 {
    font-family: $zaux-font2;
    font-weight: $zaux-typo-fw-medium;
    border-radius: $border-radius-lg;

    &--sm {
      line-height: rem(16px);
      font-size: rem(13px);
      padding: 13px;
      @include media-breakpoint-up(xlm) {
        // padding: 18px;
        padding: 16px 18px;
      }
    }

    // &--size-1 {
    //   max-height: $zaux-btn-height3;
    //   @include media-breakpoint-up(lg) {
    //     max-height: $zaux-btn-height2;
    //   }
    // }
  }

  &--space1 {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
