@charset "UTF-8";

@import "../abstracts/variables", "../abstracts/functions",
  "../abstracts/mixins", "../abstracts/placeholders";

// Vendor utils
@import "bootstrap/scss/functions", "bootstrap/scss/variables",
  "bootstrap/scss/mixins", "bootstrap/scss/utilities";

$zaux-helper-name: "size";

////////////////////////////////////////////////////
// WIDTH
////////////////////////////////////////////////////

// Box: foto home top box scuro,
.w-xl-f440 {
  @include media-breakpoint-up(xl) {
    width: 440px !important;
    max-width: none !important;
  }
}

// Box: foto home top box chiaro,
.w-xlm-f250 {
  width: 250px;
  max-width: none !important;
  @include media-breakpoint-up(xlm) {
    width: 400px;
  }
}

.w-xlm-f360 {
  width: 360px;
  max-width: none !important;
  @include media-breakpoint-up(xlm) {
    width: 460px;
  }
}

// Box: Foto dx box wide
.w-xxl-f864 {
  @include media-breakpoint-up(xxl) {
    width: 864px !important;
    max-width: none !important;
  }
}

// Box: Tools card
.w-xl-100n6 {
  @include media-breakpoint-up(xl) {
    width: calc(100% - #{map-get($spacers, "6") * 2});
  }
}

.w-lg-f250 {
  @include media-breakpoint-up(lg) {
    width: 250px;
  }
}

.w-lg-f250d {
  @include media-breakpoint-down(lg) {
    width: 250px;
  }
}



////////////////////////////////////////////////////
// MAX-WIDTH
////////////////////////////////////////////////////

// Box: foto home top box scuro,
.maw-f250 {
  max-width: 250px;
}

.maw-f400 {
  max-width: 400px;
}

.maw-f600 {
  max-width: 600px;
}

.maw-f768px {
  max-width: 768px;
}

.maw-f900 {
  max-width: 900px;
}

// Box: foto home top box scuro,
.maw-lg-100 {
  @include media-breakpoint-up(lg) {
    max-width: 100% !important;
  }
}

////////////////////////////////////////////////////
// WIDTH
////////////////////////////////////////////////////
.w-xlm-75 {
  @include media-breakpoint-down(xlm) {
    width: 75%;
  }
}

.w-xlm-65 {
  @include media-breakpoint-down(xlm) {
    width: 65%;
  }
}



////////////////////////////////////////////////////
// HEIGHT
////////////////////////////////////////////////////

.h-lg-100 {
  @include media-breakpoint-up(lg) {
    height: 100%;
  }
}

.h-custom-2 {
  height: calc(100% - 70px);
}

.h-lg-300 {
  @include media-breakpoint-up(lg) {
    height: 300px;
  }
}

////////////////////////////////////////////////////
// MIN-HEIGHT
////////////////////////////////////////////////////

.mih-f200 {
  min-height: 200px;
}

.mih-f240 {
  min-height: 240px;
}

.mih-f420 {
  min-height: 420px;
}

//Box: home left image box
.mih-lg-f100vh {
  @include media-breakpoint-up(lg) {
    min-height: calc( 100vh - 100px );
  }
}
.mih-lg-f150vh {
  @include media-breakpoint-up(lg) {
    min-height: calc( 100vh - 150px );
  }
}
.mih-f150vh {
    min-height: calc( 100vh - 150px );
  }

.mih-lg-f275vh {
  @include media-breakpoint-up(lg) {
    min-height: calc( 100vh - 275px );
  }
}
.mih-lg-f350vh {
  // min-height: calc( 100vh - 150px );
  @include media-breakpoint-up(lg) {
    min-height: calc( 100vh - 350px );
  }
}

//Box: single-page hero
.mih-lg-f400 {
  @include media-breakpoint-up(lg) {
    min-height: 400px;
  }
}

// Box: chi siamo simple grid
.mih-lg-f260 {
  @include media-breakpoint-up(lg) {
    min-height: 260px;
  }
}

.mih-lg-f420 {
  @include media-breakpoint-up(lg) {
    min-height: 420px;
  }
}

// Box: home grid,
.mih-lg-f480 {
  @include media-breakpoint-up(lg) {
    min-height: 480px;
  }
}

// Box: home grid,
.mih-xl-f400 {
  @include media-breakpoint-up(xl) {
    min-height: 400px;
  }
}

// Box: home top box scuro,
.mih-lg-f520 {
  @include media-breakpoint-up(lg) {
    min-height: 520px;
  }
}

// Box:
.mih-lg-f560 {
  @include media-breakpoint-up(lg) {
    min-height: 560px;
  }
}

// Box: home top
.mih-xxl-vh43custom1 {
  @include media-breakpoint-up(xxl) {
    min-height: 410px;
    height: calc(43.7vh - 70px);
  }
}

// Box: home top
.mih-xxl-vh60custom1 {
  @include media-breakpoint-up(xxl) {
    min-height: 508px;
    height: calc(60vh - 70px - #{map-get($spacers, "4")});
  }
}

// Grid 1: XL

.mih-xxl-f390 {
  @include media-breakpoint-up(xxl) {
    min-height: 390px;
  }
}

.mih-xxl-f490 {
  @include media-breakpoint-up(xxl) {
    min-height: 490px;
  }
}

.mih-xxl-f490-mt-nf100 {
  @include media-breakpoint-up(xxl) {
    min-height: 490px;
    margin-top: -100px;
  }
}

////////////////////////////////////////////////////
// CUSTOM
////////////////////////////////////////////////////

.zaux-#{$zaux-helper-name}-img-fluid-safe1 {
  width: auto;
  max-height: 50vh;
  min-height: 400px;
}

.zaux-#{$zaux-helper-name}-img-fluid-custom1 {
  @include media-breakpoint-down(xl) {
    max-width: 100%;
    height: auto;
  }
  @include media-breakpoint-up(xl) {
    position: absolute;
    top: 0;
    // left: 50%;
    left: 0;

    object-fit: contain;
    object-position: center center;
    // object-position: center bottom;

    width: 100%;
    height: 100%;

    // max-width: 600px;
    // transform: translateX(-50%);
  }
}

.zaux-#{$zaux-helper-name}-xs-cut1 {
  @include media-breakpoint-down(lg) {
    max-width: 340px;
    height: 240px;
  }
}

.zaux-#{$zaux-helper-name}-xs-cut2 {
  @include media-breakpoint-down(lg) {
    max-width: 280px;
    height: 210px;
  }
}

.zaux-#{$zaux-helper-name}-lg-cut3 {
  @include media-breakpoint-down(lg) {
    max-width: 200px;
    height: 250px;
  }
}


//Scale
.zaux-scale-lg-1_2 {
  @include media-breakpoint-up(lg) {
  transform: scale(1.2);
  }
}
