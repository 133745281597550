@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("wrapper");

.#{$component} {
  &--w1 {
    max-width: $zaux-app-max-width + ((parseInt($grid-gutter-width) * 16px) * 2);
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }
}

[class*="#{$component}--"] {
  margin-right: auto;
  margin-left: auto;
}
