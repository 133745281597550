@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("form");

.#{$component} {
  // @note NO
  // padding: 0 50px 50px 50px;

  & label {
    // @see src/components/typo/typo.scss
    font-size: rem(14px); // 3
    line-height: rem(20px); // 3

    font-family: $zaux-font2;
    font-weight: $zaux-typo-fw-medium;

    margin-bottom: 10px;
    padding-left: 15px;
  }

  & #footer--label {
    padding-left: 0;
  }

  & input,
  textarea,
  select {
    border-radius: $border-radius-lg;
    margin-bottom: 21px;

    &::placeholder {
      font-size: rem(16px);
      font-weight: $zaux-typo-fw-light;
      line-height: rem(20px);
    }
  }
  & select, input:not([type="radio"])  {
    height: 50px;
  }

  & select,
  option {
    font-size: rem(16px);
    font-weight: $zaux-typo-fw-light;
    line-height: rem(20px);
  }

  textarea {
    height: 200px;
    margin-bottom: 25px;
    resize: none;
    padding:10px;
  }

  & .checkbox {
    width: 100%;
    margin-bottom: 50px;

    & .form-check-label {
      font-weight: $zaux-typo-fw-regular;
      // line-height: rem(13px);

      // @see src/components/typo/typo.scss
      font-size: rem(14px); // 3
      line-height: rem(20px); // 3
      width: 100%;
      float: left;
      margin-top: -47px;

      @include media-breakpoint-up(lg) {
        line-height: rem(16px);
      }

      & a {
        white-space: nowrap;
      }
    }
    & input[type="checkbox"] {
      width: 25px;
      height: 25px;
      float: left;
      border-color: $zaux-color-set1-darkgrayish ;
    }
  }

  & .searchbar input {
    height: 50px;
    padding-left: 50px;
    @include media-breakpoint-up(lg) {
      height: 70px;
    }
    // &::placeholder {
    //   padding-left: 35px;
    // }
  }
}
