@charset "UTF-8";

@import "../abstracts/variables", "../abstracts/functions",
  "../abstracts/mixins", "../abstracts/placeholders";

// Vendor utils
@import "bootstrap/scss/functions", "bootstrap/scss/variables",
  "bootstrap/scss/mixins", "bootstrap/scss/utilities";

$zaux-helper-name: "pos";

.zaux-#{$zaux-helper-name}-lg-custom1 {
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    left: 50%;
  }
}

.zaux-#{$zaux-helper-name}-ty-f20 {
  transform: translateY(20px);
}

.zaux-#{$zaux-helper-name}-ty-f30 {
  transform: translateY(30px);
}

.zaux-#{$zaux-helper-name}-ty-f35 {
  transform: translateY(35px);
}

.zaux-#{$zaux-helper-name}-ty-f60 {
  transform: translateY(60px);
}

.zaux-#{$zaux-helper-name}-txy-f70-40 {
  transform: translate(-70px,40px);
}

.zaux-#{$zaux-helper-name}-ty-lg-none {
  @include media-breakpoint-up(lg) {
    transform: none;
  }
}

.zaux-#{$zaux-helper-name}-ty-lg-f20 {
  @include media-breakpoint-up(lg) {
    transform: translateY(30px);
  }
}

.zaux-#{$zaux-helper-name}-ty-lg-f30 {
  @include media-breakpoint-up(lg) {
    transform: translateY(30px);
  }
}

.zaux-#{$zaux-helper-name}-ty-lg-f60 {
  @include media-breakpoint-up(lg) {
    transform: translateY(60px);
  }
}

.zaux-#{$zaux-helper-name}-tx-lg-f250 {
  @include media-breakpoint-up(lg) {
  transform: translateX(-250px);
  }
}

.zaux-#{$zaux-helper-name}-ty-xl-f20 {
  @include media-breakpoint-up(xl) {
    transform: translateY(20px);
  }
}

.zaux-#{$zaux-helper-name}-ty-lg-f100 {
  @include media-breakpoint-up(lg) {
    transform: translateY(100px);
  }
}

.zaux-#{$zaux-helper-name}-z-f20 {
  position:relative;
  z-index: 20;
}
