@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("footer");

$zaux-footer-height: 395px !default;
$zaux-footer-cont-heigth: 344px;
$zaux-footer-width: 1850px;

.#{$component} {
  &__wrapper {
    &__row {
      border-radius: $border-radius-lg;
      padding: 50px;
      @include media-breakpoint-down(lg) {
        padding: 25px;
      }
      & .c-footer__logo img {
        @include media-breakpoint-down(md) {
        width: 164px;
        height: 30px;
        }
      }
    }
  }
  & nav ul {
    list-style-type: none;
  }
  & .c-form button {
    display: block;
    clear: both;
  }
  & .optin input {
    margin-bottom: 10px;
  }

  & .checkbox.optin .form-check-label {
    margin-top: -30px;
  }

    form  {
     input, .checkbox {
       width: 100%;
       @include media-breakpoint-up(md) {
       width: 80%;
      }
     }
     .checkbox {
       margin-bottom: 0;
     input[type="checkbox"] {
     border-color: $zaux-color-set1-azure1 ;
     }

    //  .form-check-label {
    //    margin-top: -45px;
    //    @include media-breakpoint-down(md) {
    //      margin-top: -40px;
    //     }
    //  }

   }
     a {
       display: inline;
       &:hover,
       &:active {
         text-decoration: underline;
       }
     }
   }

  &__last .c-typo-texts-3 p {
    margin-bottom: 0;
  }

  a {
    display: block;
    text-decoration: none;
    padding-bottom: 16px;
    font-size: rem(14px);
    line-height: rem(16px);
    font-weight: $zaux-typo-fw-regular;
  }

  p {
    margin-bottom: 0;
  }
}
