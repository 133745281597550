@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("share1");

.#{$component} {
  font-size: 1.25rem;
  line-height: 0;

  // @include media-breakpoint-up(lg) {
  position: relative;
  top: 20px;
  // }

  [class*="#{$component}__btn"] {
    &:not(:last-child) {
      margin-right: map-get($spacers, "3");
    }
  }

  &__btn-trigger.active {
    background-color: $zaux-color-set1-beaublue;
  }

  &__box {
    display: none;
    width: 100%;

    // & a {
    //   display: inline;
    // }
  }
}
