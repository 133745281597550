@import "../../assets/scss/components/base";

$component: componentClass("pagination");

.#{$component} {
  &--light {
    & a {
      background-color: $zaux-color-set1-azurewhite;
      color: $zaux-color-set1-darkblue;
      &:hover {
        background-color: $zaux-color-set1-azure1;
      }
    }
    & .current,
    .extend {
      background-color: $zaux-color-default-white;
      border: 2px solid $zaux-color-set1-azurewhite;
    }

    // .#{$component}__page div {
    //   background-color: $zaux-color-set1-azurewhite;
    //   &:hover {
    //     background-color: $zaux-color-set1-azure1;
    //   }
    //   & a {
    //     color: $zaux-color-set1-darkblue;
    //   }
    //   &.current, &.noclick {
    //     background-color: $zaux-color-default-white;
    //     border: 2px solid $zaux-color-set1-azurewhite;
    //   }
    // }
  }
}
