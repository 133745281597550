@charset "UTF-8";

$zaux-helper-name: "color";

// Onyx : https://www.color-name.com/hex/343b43

////////////////////////////////////////////////////
// MIXINS
////////////////////////////////////////////////////
@mixin zaux-helper-color($name, $color) {
  .zaux-color-#{$name} {
    color: $color !important;
  }
}

@mixin zaux-helper-bgcolor($name, $color) {
  .zaux-bg-#{$name} {
    background: $color !important;
  }
}

////////////////////////////////////////////////////
// COLOR
////////////////////////////////////////////////////
@each $colorName, $colorVal in $zaux-color-set-default {
  @include zaux-helper-color("default-#{$colorName}", $colorVal);
}

@each $colorName, $colorVal in $zaux-color-set-set1 {
  @include zaux-helper-color("set1-#{$colorName}", $colorVal);
}

////////////////////////////////////////////////////
// BG COLOR
////////////////////////////////////////////////////

@each $colorName, $colorVal in $zaux-color-set-default {
  @include zaux-helper-bgcolor("default-#{$colorName}", $colorVal);
}

@each $colorName, $colorVal in $zaux-color-set-set1 {
  @include zaux-helper-bgcolor("set1-#{$colorName}", $colorVal);
}
