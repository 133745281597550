@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card1");

.#{$component} {
  &__inner {
    position: relative;
  }
  &__foot {
    border-top-width: 2px !important;
    > div {
      position: relative;
      z-index: $zaux-zindex-contents;
    }

    [class*="ico"][class*="arrow"] {
      font-size: 10px;
    }
  }
  &__cats {
    list-style-type: none;
    li {
      display: inline-block;
      &:not(:last-child) {
        &:after {
          content: "|";
          font-family: sans-serif;
          font-weight: bold;
          // transform: translateY(-0.1em);
          display: inline-block;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
  }
}
