@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("pagination");

.#{$component} {
  margin: auto;
  text-align: center;
  padding-top: 100px;
  @include media-breakpoint-down(lg) {
    padding-top: 50px;
  }

  & .wp-pagenavi {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  & a,
  .current,
  & span.extend {
    font-family: $zaux-font2;
    font-weight: $zaux-typo-fw-medium;

    display: inline-block;
    border-radius: $border-radius-lg;
    text-decoration: none;
    margin-right: 10px;

    width: 40px;
    height: 40px;
    line-height: 40px;
    @include media-breakpoint-up(lg) {
      height: 50px;
      width: 50px;
      line-height: 50px;
    }

    &.previouspostslink {
      margin-right: 25px;
      @include media-breakpoint-up(lg) {
        margin-right: 50px;
      }
    }

    &.nextpostslink {
      margin-left: 15px;
      margin-right: 0;
      @include media-breakpoint-up(lg) {
        margin-left: 40px;
      }
    }
  }
  & .current {
    line-height: 37px;
    @include media-breakpoint-up(lg) {
      line-height: 47px;
    }
  }

  &__text {
    padding-top: 46px;
    & .pages {
      font-size: rem(14px);
      line-height: rem(16px);
    }
  }
}
