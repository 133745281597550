ul,
ol,
li,
dl,
dt,
dd,
address,
figure {
  padding: 0;
  margin: 0; }

button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0; }

[class^="icozaux1-"],
[class*=" icozaux1-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

.icozaux1-arrow {
  font-size: 10px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

:root {
  scrollbar-color: rgba(0, 0, 0, 0.25) rgba(255, 255, 255, 0.5) !important;
  scrollbar-width: thin !important; }
  :root::-webkit-scrollbar {
    width: 7px;
    height: 7px; }
  :root::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 0; }
  :root::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5); }
  :root {
    scrollbar-face-color: rgba(0, 0, 0, 0.25);
    scrollbar-track-color: rgba(255, 255, 255, 0.5); }

#zaux-content-inner {
  padding-top: 50px; }
  @media (min-width: 992px) {
    #zaux-content-inner {
      padding-left: 80px;
      padding-top: 70px; } }

main {
  min-height: calc( 100vh - 500px); }

@media (min-width: 992px) {
  .zaux-pos-lg-custom1 {
    position: absolute;
    top: 0;
    left: 50%; } }

.zaux-pos-ty-f20 {
  transform: translateY(20px); }

.zaux-pos-ty-f30 {
  transform: translateY(30px); }

.zaux-pos-ty-f35 {
  transform: translateY(35px); }

.zaux-pos-ty-f60 {
  transform: translateY(60px); }

.zaux-pos-txy-f70-40 {
  transform: translate(-70px, 40px); }

@media (min-width: 992px) {
  .zaux-pos-ty-lg-none {
    transform: none; } }

@media (min-width: 992px) {
  .zaux-pos-ty-lg-f20 {
    transform: translateY(30px); } }

@media (min-width: 992px) {
  .zaux-pos-ty-lg-f30 {
    transform: translateY(30px); } }

@media (min-width: 992px) {
  .zaux-pos-ty-lg-f60 {
    transform: translateY(60px); } }

@media (min-width: 992px) {
  .zaux-pos-tx-lg-f250 {
    transform: translateX(-250px); } }

@media (min-width: 1280px) {
  .zaux-pos-ty-xl-f20 {
    transform: translateY(20px); } }

@media (min-width: 992px) {
  .zaux-pos-ty-lg-f100 {
    transform: translateY(100px); } }

.zaux-pos-z-f20 {
  position: relative;
  z-index: 20; }

@media (min-width: 1280px) {
  .w-xl-f440 {
    width: 440px !important;
    max-width: none !important; } }

.w-xlm-f250 {
  width: 250px;
  max-width: none !important; }
  @media (min-width: 1601px) {
    .w-xlm-f250 {
      width: 400px; } }

.w-xlm-f360 {
  width: 360px;
  max-width: none !important; }
  @media (min-width: 1601px) {
    .w-xlm-f360 {
      width: 460px; } }

@media (min-width: 1580px) {
  .w-xxl-f864 {
    width: 864px !important;
    max-width: none !important; } }

@media (min-width: 1280px) {
  .w-xl-100n6 {
    width: calc(100% - 6rem); } }

@media (min-width: 992px) {
  .w-lg-f250 {
    width: 250px; } }

@media (max-width: 991.98px) {
  .w-lg-f250d {
    width: 250px; } }

.maw-f250 {
  max-width: 250px; }

.maw-f400 {
  max-width: 400px; }

.maw-f600 {
  max-width: 600px; }

.maw-f768px {
  max-width: 768px; }

.maw-f900 {
  max-width: 900px; }

@media (min-width: 992px) {
  .maw-lg-100 {
    max-width: 100% !important; } }

@media (max-width: 1600.98px) {
  .w-xlm-75 {
    width: 75%; } }

@media (max-width: 1600.98px) {
  .w-xlm-65 {
    width: 65%; } }

@media (min-width: 992px) {
  .h-lg-100 {
    height: 100%; } }

.h-custom-2 {
  height: calc(100% - 70px); }

@media (min-width: 992px) {
  .h-lg-300 {
    height: 300px; } }

.mih-f200 {
  min-height: 200px; }

.mih-f240 {
  min-height: 240px; }

.mih-f420 {
  min-height: 420px; }

@media (min-width: 992px) {
  .mih-lg-f100vh {
    min-height: calc( 100vh - 100px); } }

@media (min-width: 992px) {
  .mih-lg-f150vh {
    min-height: calc( 100vh - 150px); } }

.mih-f150vh {
  min-height: calc( 100vh - 150px); }

@media (min-width: 992px) {
  .mih-lg-f275vh {
    min-height: calc( 100vh - 275px); } }

@media (min-width: 992px) {
  .mih-lg-f350vh {
    min-height: calc( 100vh - 350px); } }

@media (min-width: 992px) {
  .mih-lg-f400 {
    min-height: 400px; } }

@media (min-width: 992px) {
  .mih-lg-f260 {
    min-height: 260px; } }

@media (min-width: 992px) {
  .mih-lg-f420 {
    min-height: 420px; } }

@media (min-width: 992px) {
  .mih-lg-f480 {
    min-height: 480px; } }

@media (min-width: 1280px) {
  .mih-xl-f400 {
    min-height: 400px; } }

@media (min-width: 992px) {
  .mih-lg-f520 {
    min-height: 520px; } }

@media (min-width: 992px) {
  .mih-lg-f560 {
    min-height: 560px; } }

@media (min-width: 1580px) {
  .mih-xxl-vh43custom1 {
    min-height: 410px;
    height: calc(43.7vh - 70px); } }

@media (min-width: 1580px) {
  .mih-xxl-vh60custom1 {
    min-height: 508px;
    height: calc(60vh - 70px - 1.5rem); } }

@media (min-width: 1580px) {
  .mih-xxl-f390 {
    min-height: 390px; } }

@media (min-width: 1580px) {
  .mih-xxl-f490 {
    min-height: 490px; } }

@media (min-width: 1580px) {
  .mih-xxl-f490-mt-nf100 {
    min-height: 490px;
    margin-top: -100px; } }

.zaux-size-img-fluid-safe1 {
  width: auto;
  max-height: 50vh;
  min-height: 400px; }

@media (max-width: 1279.98px) {
  .zaux-size-img-fluid-custom1 {
    max-width: 100%;
    height: auto; } }

@media (min-width: 1280px) {
  .zaux-size-img-fluid-custom1 {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    object-position: center center;
    width: 100%;
    height: 100%; } }

@media (max-width: 991.98px) {
  .zaux-size-xs-cut1 {
    max-width: 340px;
    height: 240px; } }

@media (max-width: 991.98px) {
  .zaux-size-xs-cut2 {
    max-width: 280px;
    height: 210px; } }

@media (max-width: 991.98px) {
  .zaux-size-lg-cut3 {
    max-width: 200px;
    height: 250px; } }

@media (min-width: 992px) {
  .zaux-scale-lg-1_2 {
    transform: scale(1.2); } }

.zaux-fit-contain-center-center {
  object-fit: contain;
  object-position: center center;
  font-family: "object-fit: contain; object-position: center center;"; }

.zaux-fit-cover-center-center {
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover; object-position: center center;"; }

.zaux-color-default-white {
  color: #FFFFFF !important; }

.zaux-color-default-black {
  color: #333333 !important; }

.zaux-color-default-red {
  color: #d9534f !important; }

.zaux-color-default-green {
  color: #5cb85c !important; }

.zaux-color-default-blue {
  color: #337ab7 !important; }

.zaux-color-default-magenta {
  color: #FF00FF !important; }

.zaux-color-default-orange {
  color: #f0ad4e !important; }

.zaux-color-set1-onyx {
  color: #343b43 !important; }

.zaux-color-set1-grey1 {
  color: #999CA0 !important; }

.zaux-color-set1-azurewhite {
  color: #EFF4F8 !important; }

.zaux-color-set1-beaublue {
  color: #C5D6EF !important; }

.zaux-color-set1-darkblue {
  color: #003567 !important; }

.zaux-color-set1-darkgrayish {
  color: #333f4c !important; }

.zaux-color-set1-azure1 {
  color: #CAE1F1 !important; }

.zaux-color-set1-azure1_to_darkblue {
  color: linear-gradient(to top, #003567, #CAE1F1) !important; }

.zaux-bg-default-white {
  background: #FFFFFF !important; }

.zaux-bg-default-black {
  background: #333333 !important; }

.zaux-bg-default-red {
  background: #d9534f !important; }

.zaux-bg-default-green {
  background: #5cb85c !important; }

.zaux-bg-default-blue {
  background: #337ab7 !important; }

.zaux-bg-default-magenta {
  background: #FF00FF !important; }

.zaux-bg-default-orange {
  background: #f0ad4e !important; }

.zaux-bg-set1-onyx {
  background: #343b43 !important; }

.zaux-bg-set1-grey1 {
  background: #999CA0 !important; }

.zaux-bg-set1-azurewhite {
  background: #EFF4F8 !important; }

.zaux-bg-set1-beaublue {
  background: #C5D6EF !important; }

.zaux-bg-set1-darkblue {
  background: #003567 !important; }

.zaux-bg-set1-darkgrayish {
  background: #333f4c !important; }

.zaux-bg-set1-azure1 {
  background: #CAE1F1 !important; }

.zaux-bg-set1-azure1_to_darkblue {
  background: linear-gradient(to top, #003567, #CAE1F1) !important; }

.zaux-svg-fluid-wrapper {
  display: block;
  line-height: 0; }
  .zaux-svg-fluid-wrapper svg {
    max-width: 100%; }

:root:not(.ie-legacy) .zaux-svg-fluid-wrapper svg {
  height: auto; }

.zaux-zi--1 {
  z-index: 1; }

[class*="c-ratio"]:before {
  display: block;
  content: " ";
  width: 100%; }

.c-ratio--1-1:before {
  padding-top: calc(1 / 1 * 100%); }

.c-ratio--38-25:before {
  padding-top: calc(25 / 38 * 100%); }

.c-ratio--16-9:before {
  padding-top: 56.25%; }

.c-accordion__head--title {
  border-bottom: 2px solid #343b43 !important;
  height: 45px; }
  @media (min-width: 1280px) {
    .c-accordion__head--title {
      height: 70px; } }

.c-accordion__head--subtitle p {
  margin-bottom: 0 !important; }

.c-accordion__head--details {
  cursor: pointer; }

.c-accordion.closed .c-accordion__head--details svg {
  transform: rotateZ(90deg);
  margin-left: 4px; }

.c-accordion.open .c-accordion__head--details svg {
  transform: rotateZ(270deg);
  margin-left: 4px; }

.c-accordion.open .c-accordion__show {
  display: block !important; }

.c-card1__inner {
  position: relative; }

.c-card1__foot {
  border-top-width: 2px !important; }
  .c-card1__foot > div {
    position: relative;
    z-index: 20; }
  .c-card1__foot [class*="ico"][class*="arrow"] {
    font-size: 10px; }

.c-card1__cats {
  list-style-type: none; }
  .c-card1__cats li {
    display: inline-block; }
    .c-card1__cats li:not(:last-child):after {
      content: "|";
      font-family: sans-serif;
      font-weight: bold;
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px; }

.c-fx--blend-multiply {
  mix-blend-mode: multiply; }

.c-fx--shadow-onyx-1 {
  box-shadow: 0px 10px 25px rgba(52, 59, 67, 0.1); }

.c-icon {
  display: inline-block;
  line-height: 0; }
  .c-icon, .c-icon[class*="-fs"] {
    line-height: 0 !important; }

.c-typo-ff-1 {
  font-family: "apparat-light", Arial, sans-serif !important; }

.c-typo-ff-2 {
  font-family: "apparat", Arial, sans-serif !important; }

.c-typo-fw-regular {
  font-weight: 400 !important; }

.c-typo-fw-medium {
  font-weight: 500 !important; }

.c-typo-fs-1 {
  font-size: 0.625rem !important;
  line-height: 0.75rem !important; }

.c-typo-fs-2 {
  font-size: 0.8125rem !important;
  line-height: 0.9375rem !important; }

.c-typo-fs-3 {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important; }

.c-typo-fs-6 {
  font-size: 1rem !important;
  line-height: 1.25rem !important; }

.c-typo-fs-8 {
  font-size: 1.25rem !important;
  line-height: 1 !important; }

.c-typo-fs-10 {
  font-size: 2rem !important;
  line-height: 1 !important; }

.c-typo-fs-13 {
  font-size: 3.125rem !important;
  line-height: 1 !important; }

.c-typo-fs-16 {
  font-size: 5rem !important;
  line-height: 1 !important; }

@media (min-width: 992px) {
  .c-typo-fs-lg-1 {
    font-size: 0.625rem !important;
    line-height: 0.75rem !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-1 {
    font-size: 0.625rem !important;
    line-height: 0.75rem !important; } }

@media (min-width: 1601px) {
  .c-typo-fs-xlm-1 {
    font-size: 0.625rem !important;
    line-height: 0.75rem !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-2 {
    font-size: 0.8125rem !important;
    line-height: 0.9375rem !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-2 {
    font-size: 0.8125rem !important;
    line-height: 0.9375rem !important; } }

@media (min-width: 1601px) {
  .c-typo-fs-xlm-2 {
    font-size: 0.8125rem !important;
    line-height: 0.9375rem !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-3 {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-3 {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important; } }

@media (min-width: 1601px) {
  .c-typo-fs-xlm-3 {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-6 {
    font-size: 1rem !important;
    line-height: 1.25rem !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-6 {
    font-size: 1rem !important;
    line-height: 1.25rem !important; } }

@media (min-width: 1601px) {
  .c-typo-fs-xlm-6 {
    font-size: 1rem !important;
    line-height: 1.25rem !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-8 {
    font-size: 1.25rem !important;
    line-height: 1 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-8 {
    font-size: 1.25rem !important;
    line-height: 1 !important; } }

@media (min-width: 1601px) {
  .c-typo-fs-xlm-8 {
    font-size: 1.25rem !important;
    line-height: 1 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-10 {
    font-size: 2rem !important;
    line-height: 1 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-10 {
    font-size: 2rem !important;
    line-height: 1 !important; } }

@media (min-width: 1601px) {
  .c-typo-fs-xlm-10 {
    font-size: 2rem !important;
    line-height: 1 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-13 {
    font-size: 3.125rem !important;
    line-height: 1 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-13 {
    font-size: 3.125rem !important;
    line-height: 1 !important; } }

@media (min-width: 1601px) {
  .c-typo-fs-xlm-13 {
    font-size: 3.125rem !important;
    line-height: 1 !important; } }

@media (min-width: 992px) {
  .c-typo-fs-lg-16 {
    font-size: 5rem !important;
    line-height: 1 !important; } }

@media (min-width: 1280px) {
  .c-typo-fs-xl-16 {
    font-size: 5rem !important;
    line-height: 1 !important; } }

@media (min-width: 1601px) {
  .c-typo-fs-xlm-16 {
    font-size: 5rem !important;
    line-height: 1 !important; } }

.c-typo-texts-1 p:not([class*="c-typo-fs-"]) {
  font-size: 0.625rem;
  line-height: 0.75rem; }

.c-typo-texts-2 p:not([class*="c-typo-fs-"]) {
  font-size: 0.8125rem;
  line-height: 0.9375rem; }

.c-typo-texts-3 p:not([class*="c-typo-fs-"]) {
  font-size: 0.875rem;
  line-height: 1.25rem; }

.c-typo-texts-6 p:not([class*="c-typo-fs-"]) {
  font-size: 1rem;
  line-height: 1.25rem; }

.c-typo-texts-8 p:not([class*="c-typo-fs-"]) {
  font-size: 1.25rem;
  line-height: 1; }

.c-typo-texts-10 p:not([class*="c-typo-fs-"]) {
  font-size: 2rem;
  line-height: 1; }

.c-typo-texts-13 p:not([class*="c-typo-fs-"]) {
  font-size: 3.125rem;
  line-height: 1; }

.c-typo-texts-16 p:not([class*="c-typo-fs-"]) {
  font-size: 5rem;
  line-height: 1; }

.c-typo-texts-1 li:not([class*="c-typo-fs-"]) {
  font-size: 0.625rem;
  line-height: 0.75rem; }

.c-typo-texts-2 li:not([class*="c-typo-fs-"]) {
  font-size: 0.8125rem;
  line-height: 0.9375rem; }

.c-typo-texts-3 li:not([class*="c-typo-fs-"]) {
  font-size: 0.875rem;
  line-height: 1.25rem; }

.c-typo-texts-6 li:not([class*="c-typo-fs-"]) {
  font-size: 1rem;
  line-height: 1.25rem; }

.c-typo-texts-8 li:not([class*="c-typo-fs-"]) {
  font-size: 1.25rem;
  line-height: 1; }

.c-typo-texts-10 li:not([class*="c-typo-fs-"]) {
  font-size: 2rem;
  line-height: 1; }

.c-typo-texts-13 li:not([class*="c-typo-fs-"]) {
  font-size: 3.125rem;
  line-height: 1; }

.c-typo-texts-16 li:not([class*="c-typo-fs-"]) {
  font-size: 5rem;
  line-height: 1; }

@media (min-width: 992px) {
  .c-typo-texts-lg-1 p:not([class*="c-typo-fs-"]) {
    font-size: 0.625rem;
    line-height: 0.75rem; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-1 p:not([class*="c-typo-fs-"]) {
    font-size: 0.625rem;
    line-height: 0.75rem; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-1 p:not([class*="c-typo-fs-"]) {
    font-size: 0.625rem;
    line-height: 0.75rem; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-2 p:not([class*="c-typo-fs-"]) {
    font-size: 0.8125rem;
    line-height: 0.9375rem; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-2 p:not([class*="c-typo-fs-"]) {
    font-size: 0.8125rem;
    line-height: 0.9375rem; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-2 p:not([class*="c-typo-fs-"]) {
    font-size: 0.8125rem;
    line-height: 0.9375rem; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-3 p:not([class*="c-typo-fs-"]) {
    font-size: 0.875rem;
    line-height: 1.25rem; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-3 p:not([class*="c-typo-fs-"]) {
    font-size: 0.875rem;
    line-height: 1.25rem; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-3 p:not([class*="c-typo-fs-"]) {
    font-size: 0.875rem;
    line-height: 1.25rem; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-6 p:not([class*="c-typo-fs-"]) {
    font-size: 1rem;
    line-height: 1.25rem; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-6 p:not([class*="c-typo-fs-"]) {
    font-size: 1rem;
    line-height: 1.25rem; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-6 p:not([class*="c-typo-fs-"]) {
    font-size: 1rem;
    line-height: 1.25rem; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-8 p:not([class*="c-typo-fs-"]) {
    font-size: 1.25rem;
    line-height: 1; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-8 p:not([class*="c-typo-fs-"]) {
    font-size: 1.25rem;
    line-height: 1; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-8 p:not([class*="c-typo-fs-"]) {
    font-size: 1.25rem;
    line-height: 1; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-10 p:not([class*="c-typo-fs-"]) {
    font-size: 2rem;
    line-height: 1; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-10 p:not([class*="c-typo-fs-"]) {
    font-size: 2rem;
    line-height: 1; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-10 p:not([class*="c-typo-fs-"]) {
    font-size: 2rem;
    line-height: 1; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-13 p:not([class*="c-typo-fs-"]) {
    font-size: 3.125rem;
    line-height: 1; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-13 p:not([class*="c-typo-fs-"]) {
    font-size: 3.125rem;
    line-height: 1; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-13 p:not([class*="c-typo-fs-"]) {
    font-size: 3.125rem;
    line-height: 1; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-16 p:not([class*="c-typo-fs-"]) {
    font-size: 5rem;
    line-height: 1; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-16 p:not([class*="c-typo-fs-"]) {
    font-size: 5rem;
    line-height: 1; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-16 p:not([class*="c-typo-fs-"]) {
    font-size: 5rem;
    line-height: 1; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-1 li:not([class*="c-typo-fs-"]) {
    font-size: 0.625rem;
    line-height: 0.75rem; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-1 li:not([class*="c-typo-fs-"]) {
    font-size: 0.625rem;
    line-height: 0.75rem; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-1 li:not([class*="c-typo-fs-"]) {
    font-size: 0.625rem;
    line-height: 0.75rem; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-2 li:not([class*="c-typo-fs-"]) {
    font-size: 0.8125rem;
    line-height: 0.9375rem; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-2 li:not([class*="c-typo-fs-"]) {
    font-size: 0.8125rem;
    line-height: 0.9375rem; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-2 li:not([class*="c-typo-fs-"]) {
    font-size: 0.8125rem;
    line-height: 0.9375rem; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-3 li:not([class*="c-typo-fs-"]) {
    font-size: 0.875rem;
    line-height: 1.25rem; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-3 li:not([class*="c-typo-fs-"]) {
    font-size: 0.875rem;
    line-height: 1.25rem; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-3 li:not([class*="c-typo-fs-"]) {
    font-size: 0.875rem;
    line-height: 1.25rem; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-6 li:not([class*="c-typo-fs-"]) {
    font-size: 1rem;
    line-height: 1.25rem; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-6 li:not([class*="c-typo-fs-"]) {
    font-size: 1rem;
    line-height: 1.25rem; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-6 li:not([class*="c-typo-fs-"]) {
    font-size: 1rem;
    line-height: 1.25rem; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-8 li:not([class*="c-typo-fs-"]) {
    font-size: 1.25rem;
    line-height: 1; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-8 li:not([class*="c-typo-fs-"]) {
    font-size: 1.25rem;
    line-height: 1; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-8 li:not([class*="c-typo-fs-"]) {
    font-size: 1.25rem;
    line-height: 1; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-10 li:not([class*="c-typo-fs-"]) {
    font-size: 2rem;
    line-height: 1; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-10 li:not([class*="c-typo-fs-"]) {
    font-size: 2rem;
    line-height: 1; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-10 li:not([class*="c-typo-fs-"]) {
    font-size: 2rem;
    line-height: 1; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-13 li:not([class*="c-typo-fs-"]) {
    font-size: 3.125rem;
    line-height: 1; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-13 li:not([class*="c-typo-fs-"]) {
    font-size: 3.125rem;
    line-height: 1; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-13 li:not([class*="c-typo-fs-"]) {
    font-size: 3.125rem;
    line-height: 1; } }

@media (min-width: 992px) {
  .c-typo-texts-lg-16 li:not([class*="c-typo-fs-"]) {
    font-size: 5rem;
    line-height: 1; } }

@media (min-width: 1280px) {
  .c-typo-texts-xl-16 li:not([class*="c-typo-fs-"]) {
    font-size: 5rem;
    line-height: 1; } }

@media (min-width: 1601px) {
  .c-typo-texts-xlm-16 li:not([class*="c-typo-fs-"]) {
    font-size: 5rem;
    line-height: 1; } }

[class*="c-typo-texts-"] p,
[class*="c-typo-texts-"] li {
  font-weight: 400; }

[class*="c-typo-texts-"] strong {
  font-weight: 500;
  font-family: "apparat", Arial, sans-serif; }

[class*="c-typo-texts-"] li {
  margin-bottom: 1rem; }

[class*="c-typo-texts-"] p,
[class*="c-typo-texts-"] ul:not(:last-child),
[class*="c-typo-texts-"] ol:not(:last-child) {
  margin-bottom: 1.5rem; }

[class*="c-typo-texts-"] ul,
[class*="c-typo-texts-"] ol {
  padding-left: 1rem; }

[class*="c-typo-texts-"] ul ul:first-child,
[class*="c-typo-texts-"] ol ol:first-child {
  padding-top: 0.5rem; }

.c-typo-lh-0 {
  line-height: 0 !important; }

.c-typo-lh-1 {
  line-height: 1 !important; }

.c-typo-texts-6.mb-0 p {
  margin-bottom: 0 !important; }

.c-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30;
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%; }
  @media (max-width: 991.98px) {
    .c-header__logo {
      width: 172px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%; }
      .c-header__logo > div {
        height: 100%;
        display: flex;
        align-items: center; } }
  .c-header__inner {
    transition: height 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    height: 50px; }
    @media (min-width: 992px) {
      .c-header__inner {
        height: 70px; } }
  @media (min-width: 992px) {
    .c-header .ms-lg-6 {
      margin-left: 25px !important; } }
  .c-header__search {
    width: 150px;
    position: relative; }
    .c-header__search [type="search"]::-ms-clear,
    .c-header__search [type="search"]::-ms-reveal,
    .c-header__search [type="search"]::-webkit-search-decoration,
    .c-header__search [type="search"]::-webkit-search-cancel-button,
    .c-header__search [type="search"]::-webkit-search-results-button,
    .c-header__search [type="search"]::-webkit-search-results-decoration {
      display: none !important; }
    .c-header__search > .c-form {
      opacity: 0.5;
      transition: all 0.6s ease-in-out;
      position: fixed;
      right: 1rem;
      width: 2rem;
      top: 4px; }
      @media (min-width: 992px) {
        .c-header__search > .c-form {
          top: 12px;
          right: 240px;
          width: 150px; } }
      .c-header__search > .c-form button {
        float: left;
        margin: 0 0 0 -28px;
        transform: translate(40px, 12px); }
        @media (min-width: 992px) {
          .c-header__search > .c-form button {
            transform: translate(40px, 15px); } }
      .c-header__search > .c-form .form-control {
        border: 0;
        padding-left: 33px;
        height: 43px;
        font-family: "apparat", Arial, sans-serif;
        font-size: 14px;
        line-height: 1;
        outline: none !important;
        box-shadow: none !important; }
        .c-header__search > .c-form .form-control::placeholder {
          font-family: "apparat", Arial, sans-serif;
          font-size: 14px;
          line-height: 1; }
        .c-header__search > .c-form .form-control::-webkit-input-placeholder {
          font-family: "apparat", Arial, sans-serif;
          font-size: 14px;
          line-height: 1; }
        .c-header__search > .c-form .form-control:-moz-placeholder {
          font-family: "apparat", Arial, sans-serif;
          font-size: 14px;
          line-height: 1; }
        .c-header__search > .c-form .form-control::-moz-placeholder {
          font-family: "apparat", Arial, sans-serif;
          font-size: 14px;
          line-height: 1; }
        .c-header__search > .c-form .form-control:-ms-input-placeholder {
          font-family: "apparat", Arial, sans-serif;
          font-size: 14px;
          line-height: 1; }
      .zaux-search-active .c-header__search > .c-form {
        opacity: 1;
        width: calc(100% - 68px);
        right: 1rem; }
        @media (min-width: 992px) {
          .zaux-search-active .c-header__search > .c-form {
            width: calc(100vw - 1rem * 7.3); } }
        .zaux-search-active .c-header__search > .c-form .form-control {
          border: 2px solid #EFF4F8; }
  .c-header__search-btn {
    font-weight: 500; }
  @media (min-width: 992px) {
    .c-header__inner {
      padding-left: 70px; } }

.c-fixed-side-nav {
  background-color: #EFF4F8 !important; }

[class*="c-nav2"] ul {
  list-style: none; }

[class*="c-nav2"] li {
  font-family: "apparat", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem; }
  [class*="c-nav2"] li:not(:last-child) {
    margin-bottom: 0.25rem; }
    @media (min-width: 992px) {
      [class*="c-nav2"] li:not(:last-child) {
        margin-bottom: 1.5rem; } }

.c-nav2__item-wrapper {
  position: relative;
  display: block;
  border-radius: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media (min-width: 992px) {
    .c-nav2__item-wrapper {
      padding-left: 1rem;
      padding-right: 1rem; } }

.c-nav2__item-label, .c-nav2__item-description {
  display: block; }

.c-nav2__item-description {
  padding-top: 0.5rem;
  font-family: "apparat-light", Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem; }

.c-nav2__item[class*="-has-icon"] .c-nav2__item-wrapper {
  display: block;
  padding-left: 3rem; }
  @media (min-width: 992px) {
    .c-nav2__item[class*="-has-icon"] .c-nav2__item-wrapper {
      padding-left: 58px; } }

.c-nav2__item[class*="-has-icon"] .c-nav2__item-icon {
  float: left;
  margin-left: -2rem;
  font-size: 1.25rem;
  transform: translateY(-1px); }
  @media (min-width: 992px) {
    .c-nav2__item[class*="-has-icon"] .c-nav2__item-icon {
      margin-left: -43px; } }

.c-nav2__item[class*="-has-child"] > .c-nav2__item-wrapper {
  position: relative;
  padding-right: 1.5rem; }
  .c-nav2__item[class*="-has-child"] > .c-nav2__item-wrapper::after {
    transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: " ";
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 64 64'%3E%3Ctitle%3Earrow%3C/title%3E%3Cpath fill='%23343b43' d='M19.2 63.961c-3.534-0.001-6.399-2.866-6.399-6.4 0-1.767 0.716-3.367 1.874-4.525l21.075-21.075-21.075-21.075c-1.112-1.15-1.797-2.718-1.797-4.446 0-3.535 2.865-6.4 6.4-6.4 1.728 0 3.296 0.685 4.448 1.798l-0.002-0.002 25.6 25.6c1.158 1.158 1.874 2.758 1.874 4.525s-0.716 3.367-1.874 4.525l-25.6 25.6c-1.158 1.158-2.758 1.875-4.525 1.875h-0z'%3E%3C/path%3E%3C/svg%3E%0A");
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 11px;
    transform: translateY(-50%); }

.c-nav2__item[class*="--type-micro"] {
  margin-bottom: 16px !important; }
  @media (min-width: 992px) {
    .c-nav2__item[class*="--type-micro"] {
      margin-bottom: 36px !important; } }
  .c-nav2__item[class*="--type-micro"] .c-nav2__item-wrapper {
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .c-nav2__item[class*="--type-micro"] .c-nav2__item-icon {
    float: none;
    margin: 0; }

.c-footer__wrapper__row {
  border-radius: 0.5rem;
  padding: 50px; }
  @media (max-width: 991.98px) {
    .c-footer__wrapper__row {
      padding: 25px; } }
  .c-footer__wrapper__row .c-footer__logo img {
    width: 164px;
    height: 30px; }

.c-footer nav ul {
  list-style-type: none; }

.c-footer .c-form button {
  display: block;
  clear: both; }

.c-footer .optin input {
  margin-bottom: 10px; }

.c-footer .checkbox.optin .form-check-label {
  margin-top: -30px; }

.c-footer form input, .c-footer form .checkbox {
  width: 100%;
  width: 80%; }

.c-footer form .checkbox {
  margin-bottom: 0; }
  .c-footer form .checkbox input[type="checkbox"] {
    border-color: #CAE1F1; }

.c-footer form a {
  display: inline; }
  .c-footer form a:hover, .c-footer form a:active {
    text-decoration: underline; }

.c-footer__last .c-typo-texts-3 p {
  margin-bottom: 0; }

.c-footer a {
  display: block;
  text-decoration: none;
  padding-bottom: 16px;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400; }

.c-footer p {
  margin-bottom: 0; }

[class*="c-nav1"] ul {
  list-style: none;
  display: flex; }

.c-nav1--layout1 ul {
  align-items: center; }

.c-nav1--layout1 li {
  font-family: "apparat", Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem; }
  .c-nav1--layout1 li:not(:last-child) {
    margin-right: 3rem; }

.c-nav1--layout2 ul {
  align-items: center; }

.c-nav1--layout2 li:not(:last-child) {
  margin-right: 1.5rem; }

.c-nav1--layout3 ul {
  display: block; }

.c-nav1--layout3 li:not(:last-child) {
  margin-bottom: 1.5rem; }

.c-nav1--fh ul {
  height: 100%; }

.zaux-sidebar-active {
  overflow: hidden; }

@media (min-width: 992px) {
  .zaux-show-on-sidebar-active {
    transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0; }
    .zaux-sidebar-active .zaux-show-on-sidebar-active {
      opacity: 1; } }

.c-sidebar [class*="__btn-"] {
  padding: 0; }

.c-sidebar .px-2 {
  padding-left: 14px !important;
  padding-right: 8px !important; }
  @media (min-width: 992px) {
    .c-sidebar .px-2 {
      padding-left: 10px !important;
      padding-right: 10px !important; } }

.c-sidebar .c-sidebar__panel-foot .c-btn1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.c-sidebar, .c-sidebar__panel, .c-sidebar__panel-inner, .c-sidebar__panel-head, .c-sidebar__panel-content {
  height: 100%; }

.c-sidebar__breadcrumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .c-sidebar__breadcrumb-wrapper {
    height: 100%; }

@media (max-width: 991.98px) {
  .c-sidebar__panel-side {
    border-right: 2px solid #FFFFFF;
    position: absolute;
    top: 50px;
    left: 0;
    width: 52px;
    overflow: hidden;
    height: calc(100% - 50px);
    z-index: 1;
    padding-top: 1rem;
    padding-left: 5px; }
    .c-sidebar__panel-side ~ .c-sidebar__panel-head .c-sidebar__panel-content,
    .c-sidebar__panel-side ~ .c-sidebar__panel-foot {
      margin-left: 52px; } }

.c-sidebar__logo {
  width: 172px; }
  .c-sidebar__logo > div {
    height: 100%;
    display: flex;
    align-items: center; }
  @media (max-width: 991.98px) {
    .c-sidebar__logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%; } }

.c-sidebar__panel-top {
  position: relative; }

.c-sidebar__panel-content {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .c-sidebar__panel-content::-webkit-scrollbar {
    width: 0 !important; }

.c-sidebar__panel-foot {
  order: 2; }

@media (max-width: 991.98px) {
  .c-sidebar__btn-prev, .c-sidebar__btn-toggle {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0; } }

@media (min-width: 992px) {
  .c-sidebar__btn-toggle {
    transform: translateY(-3px); } }

.c-sidebar__panel {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  transition: width 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
  @media (max-width: 991.98px) {
    .c-sidebar__panel {
      z-index: 50;
      transform: translateX(100%);
      width: 100%; } }
  @media (min-width: 992px) {
    .c-sidebar__panel {
      z-index: -1;
      opacity: 0; } }
  .c-sidebar__panel--layout1 .c-sidebar__panel-top {
    height: 50px; }
  .c-sidebar__panel--layout1 .c-sidebar__panel-head {
    height: 100%;
    order: 2; }
    @media (max-width: 991.98px) {
      .c-sidebar__panel--layout1 .c-sidebar__panel-head {
        order: 1; } }
  .c-sidebar__panel--layout1 .c-sidebar__panel-content {
    height: calc(100% - 50px); }
    @media (max-width: 991.98px) {
      .c-sidebar__panel--layout1 .c-sidebar__panel-content {
        padding-top: 10px; } }
    @media (min-width: 992px) {
      .c-sidebar__panel--layout1 .c-sidebar__panel-content {
        height: 100%; } }
  .c-sidebar__panel--layout1 .c-sidebar__panel-foot {
    height: 5rem;
    order: 1;
    margin-top: 70px; }
    @media (max-width: 991.98px) {
      .c-sidebar__panel--layout1 .c-sidebar__panel-foot {
        order: 2;
        margin-top: 0; } }
  .c-sidebar__panel--layout1 .c-sidebar__panel-foot + .c-sidebar__panel-head {
    height: calc(100% - 5rem); }
  .c-sidebar__panel--layout2 .c-sidebar__panel-top {
    height: 50px; }
    @media (min-width: 992px) {
      .c-sidebar__panel--layout2 .c-sidebar__panel-top {
        height: 70px; } }
  .c-sidebar__panel--layout2 .c-sidebar__panel-head {
    height: 100%; }
  .c-sidebar__panel--layout2 .c-sidebar__panel-content {
    height: calc(100% - 50px); }
    @media (max-width: 991.98px) {
      .c-sidebar__panel--layout2 .c-sidebar__panel-content {
        padding-top: 10px; } }
    @media (min-width: 992px) {
      .c-sidebar__panel--layout2 .c-sidebar__panel-content {
        height: 100%; } }
  .c-sidebar__panel--layout2 .c-sidebar__panel-foot {
    height: 10rem; }
    @media (max-width: 991.98px) {
      .c-sidebar__panel--layout2 .c-sidebar__panel-foot {
        display: none; } }
  @media (min-width: 992px) {
    .c-sidebar__panel--layout2 .c-sidebar__panel-foot + .c-sidebar__panel-head {
      height: calc(100% - 10rem); } }
  @media (min-width: 992px) {
    .c-sidebar__panel[data-level="1"] {
      width: 70px;
      z-index: 50;
      opacity: 1; } }
  @media (min-width: 992px) {
    .c-sidebar__panel.open {
      z-index: 999 !important; } }
  @media (max-width: 991.98px) {
    .c-sidebar__panel.open, .c-sidebar__panel.opening {
      transform: translateX(0); } }
  @media (min-width: 992px) {
    .c-sidebar__panel.open, .c-sidebar__panel.opening {
      transform: translateX(100%);
      opacity: 1; } }
  @media (max-width: 991.98px) {
    .zaux-sidebar-active .c-sidebar__panel[data-level="1"] {
      transform: translateX(0); } }
  @media (max-width: 991.98px) {
    .c-sidebar__panel[data-level="2"].open, .c-sidebar__panel[data-level="2"].opening, .c-sidebar__panel[data-level="2"].closing {
      z-index: 54; } }
  @media (min-width: 992px) {
    .c-sidebar__panel[data-level="2"] {
      left: 0; }
      .c-sidebar__panel[data-level="2"].open, .c-sidebar__panel[data-level="2"].opening, .c-sidebar__panel[data-level="2"].closing {
        z-index: 46; } }
  @media (max-width: 991.98px) {
    .c-sidebar__panel[data-level="3"].open, .c-sidebar__panel[data-level="3"].opening, .c-sidebar__panel[data-level="3"].closing {
      z-index: 56; } }
  @media (min-width: 992px) {
    .c-sidebar__panel[data-level="3"] {
      left: 340px; }
      .c-sidebar__panel[data-level="3"].open, .c-sidebar__panel[data-level="3"].opening, .c-sidebar__panel[data-level="3"].closing {
        z-index: 44; } }
  @media (max-width: 991.98px) {
    .c-sidebar__panel[data-level="4"].open, .c-sidebar__panel[data-level="4"].opening, .c-sidebar__panel[data-level="4"].closing {
      z-index: 58; } }
  @media (min-width: 992px) {
    .c-sidebar__panel[data-level="4"] {
      left: 680px; }
      .c-sidebar__panel[data-level="4"].open, .c-sidebar__panel[data-level="4"].opening, .c-sidebar__panel[data-level="4"].closing {
        z-index: 42; } }
  @media (max-width: 991.98px) {
    .c-sidebar__panel[data-level="5"].open, .c-sidebar__panel[data-level="5"].opening, .c-sidebar__panel[data-level="5"].closing {
      z-index: 60; } }
  @media (min-width: 992px) {
    .c-sidebar__panel[data-level="5"] {
      left: 1020px; }
      .c-sidebar__panel[data-level="5"].open, .c-sidebar__panel[data-level="5"].opening, .c-sidebar__panel[data-level="5"].closing {
        z-index: 40; } }
  @media (min-width: 992px) {
    .zaux-sidebar-active .c-sidebar__panel {
      width: 340px; } }
  .c-sidebar__panel-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (max-width: 991.98px) {
      .c-sidebar__panel-inner {
        width: 100%; } }
    @media (min-width: 992px) {
      .c-sidebar__panel-inner {
        width: 340px; }
        .c-sidebar__panel-inner:before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 1px;
          height: calc(100vh - 70px);
          border-left: 2px solid #FFFFFF;
          z-index: 999; } }

@media (min-width: 992px) {
  .c-sidebar__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 39;
    display: none;
    background-color: rgba(0, 0, 0, 0.5); } }

.c-sidebar .c-nav2__item-wrapper::after {
  opacity: 0; }
  .zaux-sidebar-active .c-sidebar .c-nav2__item-wrapper::after {
    opacity: 1; }

.c-sidebar .c-btn1 {
  max-width: none; }

.debug .c-sidebar .zaux-show-on-sidebar-active {
  opacity: 1 !important; }

.debug .c-sidebar__panel[data-level] {
  width: 340px !important;
  transform: none !important; }

.debug .c-sidebar #sidebar-panel-1 {
  z-index: 9999;
  opacity: 123;
  left: 0; }

.debug .c-sidebar #sidebar-panel-2 {
  z-index: 9999;
  opacity: 123;
  left: 340px; }

.debug .c-sidebar #sidebar-panel-3 {
  z-index: 9999;
  opacity: 123;
  left: 680px; }

.c-wrapper--w1 {
  max-width: 1832px;
  padding-right: 1rem;
  padding-left: 1rem; }

[class*="c-wrapper--"] {
  margin-right: auto;
  margin-left: auto; }

.c-slider__wrapper {
  overflow: hidden;
  position: relative; }
  .c-slider__wrapper .swiper-slide {
    height: auto; }

.c-slider .swiper-pagination {
  margin: auto;
  position: relative;
  transform: translateY(15px); }
  .c-slider .swiper-pagination-bullet {
    width: 10px;
    height: 4px;
    background-color: #C5D6EF;
    border-radius: 10px; }
  .c-slider .swiper-pagination-bullet-active {
    width: 20px;
    background-color: #343b43; }

.c-img {
  line-height: 0;
  display: block; }
  .c-img img {
    opacity: 1;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .c-img__hover:hover {
    transform: scale(1.1); }
  .c-img:not(.c-img--lazy).c-img--fluid img {
    max-width: 100%;
    height: auto; }
  .c-img--lazy.c-img--fluid.-is-loaded img {
    max-width: 100%;
    height: auto; }
  .c-img--lazy:not([class*="-is-loaded"]) img {
    opacity: 0; }

a[class*="c-btn"] {
  display: inline-block;
  text-decoration: none; }

[class*="c-btn"][class*="--size"] {
  text-align: center; }

.c-btn1 {
  width: 100%;
  font-family: "apparat", Arial, sans-serif;
  font-weight: 500;
  border-radius: 0.5rem; }
  .c-btn1--sm {
    line-height: 1rem;
    font-size: 1rem;
    padding: 1rem; }
    @media (min-width: 1601px) {
      .c-btn1--sm {
        line-height: 1.6875rem;
        font-size: 1.25rem;
        padding: 1.35rem; } }
  .c-btn1--ct {
    line-height: 1.3125rem;
    font-size: 1rem;
    padding: 1rem; }
  .c-btn1--footer {
    line-height: 1rem;
    font-size: 0.8125rem;
    padding: 1rem;
    width: 70%; }
  .c-btn1--share {
    padding: 0.8125rem; }
    @media (min-width: 1601px) {
      .c-btn1--share {
        padding: 1.5rem; } }
  .c-btn1--header {
    padding: 0.8125rem; }
  .c-btn1--size-l {
    min-width: 200px;
    width: auto; }
    @media (min-width: 1601px) {
      .c-btn1--size-l {
        min-width: 300px; } }
  .c-btn1--size-2 {
    max-width: 310px; }
    @media (min-width: 1601px) {
      .c-btn1--size-2 {
        max-width: 320px; } }
  .c-btn1--size-3 {
    max-width: 100px; }
  .c-btn1--size-4 {
    max-width: 50px; }
    @media (min-width: 1601px) {
      .c-btn1--size-4 {
        max-width: 70px; } }
  .c-btn1--size-5 {
    max-width: 230px; }
    @media (min-width: 1601px) {
      .c-btn1--size-5 {
        max-width: 300px; } }
  .c-btn1--size-6 {
    max-width: 50px; }

.c-btn2 {
  font-family: "apparat", Arial, sans-serif;
  font-weight: 500;
  border-radius: 0.5rem; }
  .c-btn2--sm {
    line-height: 1rem;
    font-size: 0.8125rem;
    padding: 13px; }
    @media (min-width: 1601px) {
      .c-btn2--sm {
        padding: 16px 18px; } }

.c-btn--space1 {
  margin-right: 10px;
  margin-bottom: 10px; }

.c-form label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: "apparat", Arial, sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 15px; }

.c-form #footer--label {
  padding-left: 0; }

.c-form input,
.c-form textarea,
.c-form select {
  border-radius: 0.5rem;
  margin-bottom: 21px; }
  .c-form input::placeholder,
  .c-form textarea::placeholder,
  .c-form select::placeholder {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.25rem; }

.c-form select, .c-form input:not([type="radio"]) {
  height: 50px; }

.c-form select,
.c-form option {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25rem; }

.c-form textarea {
  height: 200px;
  margin-bottom: 25px;
  resize: none;
  padding: 10px; }

.c-form .checkbox {
  width: 100%;
  margin-bottom: 50px; }
  .c-form .checkbox .form-check-label {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 100%;
    float: left;
    margin-top: -47px; }
    @media (min-width: 992px) {
      .c-form .checkbox .form-check-label {
        line-height: 1rem; } }
    .c-form .checkbox .form-check-label a {
      white-space: nowrap; }
  .c-form .checkbox input[type="checkbox"] {
    width: 25px;
    height: 25px;
    float: left;
    border-color: #333f4c; }

.c-form .searchbar input {
  height: 50px;
  padding-left: 50px; }
  @media (min-width: 992px) {
    .c-form .searchbar input {
      height: 70px; } }

.c-modale .modal-dialog {
  max-width: 900px; }
  @media (max-width: 991.98px) {
    .c-modale .modal-dialog {
      margin: 50px auto; } }

.c-modale__content--footer {
  z-index: 999;
  position: relative; }

@media (max-width: 991.98px) {
  .c-modale .row {
    display: block; } }

.btn.btn-primary {
  white-space: nowrap;
  color: #003567;
  text-decoration: none; }

.c-pagination {
  margin: auto;
  text-align: center;
  padding-top: 100px; }
  @media (max-width: 991.98px) {
    .c-pagination {
      padding-top: 50px; } }
  .c-pagination .wp-pagenavi {
    align-items: center;
    justify-content: center;
    display: flex; }
  .c-pagination a,
  .c-pagination .current,
  .c-pagination span.extend {
    font-family: "apparat", Arial, sans-serif;
    font-weight: 500;
    display: inline-block;
    border-radius: 0.5rem;
    text-decoration: none;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    line-height: 40px; }
    @media (min-width: 992px) {
      .c-pagination a,
      .c-pagination .current,
      .c-pagination span.extend {
        height: 50px;
        width: 50px;
        line-height: 50px; } }
    .c-pagination a.previouspostslink,
    .c-pagination .current.previouspostslink,
    .c-pagination span.extend.previouspostslink {
      margin-right: 25px; }
      @media (min-width: 992px) {
        .c-pagination a.previouspostslink,
        .c-pagination .current.previouspostslink,
        .c-pagination span.extend.previouspostslink {
          margin-right: 50px; } }
    .c-pagination a.nextpostslink,
    .c-pagination .current.nextpostslink,
    .c-pagination span.extend.nextpostslink {
      margin-left: 15px;
      margin-right: 0; }
      @media (min-width: 992px) {
        .c-pagination a.nextpostslink,
        .c-pagination .current.nextpostslink,
        .c-pagination span.extend.nextpostslink {
          margin-left: 40px; } }
  .c-pagination .current {
    line-height: 37px; }
    @media (min-width: 992px) {
      .c-pagination .current {
        line-height: 47px; } }
  .c-pagination__text {
    padding-top: 46px; }
    .c-pagination__text .pages {
      font-size: 0.875rem;
      line-height: 1rem; }

.c-breadcrumbs > span, .c-breadcrumbs a {
  font-family: "apparat-light", Arial, sans-serif;
  color: #003567;
  font-size: 1rem;
  line-height: 1.25rem; }
  @media (min-width: 992px) {
    .c-breadcrumbs > span, .c-breadcrumbs a {
      font-size: 1.25rem;
      line-height: 1; } }

.c-share1 {
  font-size: 1.25rem;
  line-height: 0;
  position: relative;
  top: 20px; }
  .c-share1 [class*="c-share1__btn"]:not(:last-child) {
    margin-right: 1rem; }
  .c-share1__btn-trigger.active {
    background-color: #C5D6EF; }
  .c-share1__box {
    display: none;
    width: 100%; }

.zaux-theme--light1 h1,
.zaux-theme--light1 .h1,
.zaux-theme--light1 h2,
.zaux-theme--light1 .h2,
.zaux-theme--light1 h3,
.zaux-theme--light1 .h3,
.zaux-theme--light1 h4,
.zaux-theme--light1 .h4,
.zaux-theme--light1 h5,
.zaux-theme--light1 .h5,
.zaux-theme--light1 h6,
.zaux-theme--light1 .h6,
.zaux-theme--light1 p,
.zaux-theme--light1 li {
  color: #343b43; }

.zaux-components-loaded .zaux-theme--light1 a {
  transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

.zaux-theme--light1 a:link, .zaux-theme--light1 a:visited {
  color: #003567; }

.zaux-theme--light1 a:hover, .zaux-theme--light1 a:active {
  color: #003567;
  text-decoration: none; }

.zaux-theme--light2 h1,
.zaux-theme--light2 .h1,
.zaux-theme--light2 h2,
.zaux-theme--light2 .h2,
.zaux-theme--light2 h3,
.zaux-theme--light2 .h3,
.zaux-theme--light2 h4,
.zaux-theme--light2 .h4,
.zaux-theme--light2 h5,
.zaux-theme--light2 .h5,
.zaux-theme--light2 h6,
.zaux-theme--light2 .h6,
.zaux-theme--light2 p,
.zaux-theme--light2 li {
  color: #343b43;
  list-style-type: none; }

.zaux-theme--light2 ul {
  padding-left: 0; }

.zaux-theme--light2 a {
  color: #003567; }
  .zaux-components-loaded .zaux-theme--light2 a {
    transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .zaux-theme--light2 a:link, .zaux-theme--light2 a:visited {
    color: #003567; }
  .zaux-theme--light2 a:hover, .zaux-theme--light2 a:active {
    text-decoration: underline; }

.zaux-theme--light3 h1,
.zaux-theme--light3 .h1,
.zaux-theme--light3 h2,
.zaux-theme--light3 .h2,
.zaux-theme--light3 h3,
.zaux-theme--light3 .h3,
.zaux-theme--light3 h4,
.zaux-theme--light3 .h4,
.zaux-theme--light3 h5,
.zaux-theme--light3 .h5,
.zaux-theme--light3 h6,
.zaux-theme--light3 .h6,
.zaux-theme--light3 p,
.zaux-theme--light3 li {
  color: #343b43; }

.zaux-theme--light3 a {
  color: #343b43; }
  .zaux-components-loaded .zaux-theme--light3 a {
    transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .zaux-theme--light3 a:link, .zaux-theme--light3 a:visited {
    color: #343b43; }
  .zaux-theme--light3 a:hover, .zaux-theme--light3 a:active {
    color: #343b43;
    text-decoration: none; }

.zaux-theme--light4 h1,
.zaux-theme--light4 .h1,
.zaux-theme--light4 h2,
.zaux-theme--light4 .h2,
.zaux-theme--light4 h3,
.zaux-theme--light4 .h3,
.zaux-theme--light4 h4,
.zaux-theme--light4 .h4,
.zaux-theme--light4 h5,
.zaux-theme--light4 .h5,
.zaux-theme--light4 h6,
.zaux-theme--light4 .h6,
.zaux-theme--light4 p,
.zaux-theme--light4 li {
  color: #003567; }

.zaux-theme--light4 a {
  color: #003567; }
  .zaux-components-loaded .zaux-theme--light4 a {
    transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .zaux-theme--light4 a:link, .zaux-theme--light4 a:visited {
    color: #003567; }
  .zaux-theme--light4 a:hover, .zaux-theme--light4 a:active {
    color: #343b43;
    text-decoration: none; }

.zaux-theme--dark1 h1,
.zaux-theme--dark1 .h1,
.zaux-theme--dark1 h2,
.zaux-theme--dark1 .h2,
.zaux-theme--dark1 h3,
.zaux-theme--dark1 .h3,
.zaux-theme--dark1 h4,
.zaux-theme--dark1 .h4,
.zaux-theme--dark1 h5,
.zaux-theme--dark1 .h5,
.zaux-theme--dark1 h6,
.zaux-theme--dark1 .h6 {
  color: #FFFFFF; }

.zaux-theme--dark1 p,
.zaux-theme--dark1 li {
  color: #EFF4F8; }

.zaux-components-loaded .zaux-theme--dark1 a {
  transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

.zaux-theme--dark1 a:link, .zaux-theme--dark1 a:visited {
  color: #EFF4F8; }

.zaux-theme--dark1 a:hover, .zaux-theme--dark1 a:active {
  color: #EFF4F8;
  text-decoration: underline; }

.zaux-theme--dark2 h1,
.zaux-theme--dark2 .h1,
.zaux-theme--dark2 h2,
.zaux-theme--dark2 .h2,
.zaux-theme--dark2 h3,
.zaux-theme--dark2 .h3,
.zaux-theme--dark2 h4,
.zaux-theme--dark2 .h4,
.zaux-theme--dark2 h5,
.zaux-theme--dark2 .h5,
.zaux-theme--dark2 h6,
.zaux-theme--dark2 .h6 {
  color: #FFFFFF; }

.zaux-theme--dark2 p,
.zaux-theme--dark2 li {
  color: #EFF4F8; }

.zaux-components-loaded .zaux-theme--dark2 a {
  transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

.zaux-theme--dark2 a:link, .zaux-theme--dark2 a:visited {
  color: #CAE1F1; }

.zaux-theme--dark2 a:hover, .zaux-theme--dark2 a:active {
  color: #FFFFFF;
  text-decoration: none; }

.zaux-theme--darkgrayish h1,
.zaux-theme--darkgrayish .h1,
.zaux-theme--darkgrayish h2,
.zaux-theme--darkgrayish .h2,
.zaux-theme--darkgrayish h3,
.zaux-theme--darkgrayish .h3,
.zaux-theme--darkgrayish h4,
.zaux-theme--darkgrayish .h4,
.zaux-theme--darkgrayish h5,
.zaux-theme--darkgrayish .h5,
.zaux-theme--darkgrayish h6,
.zaux-theme--darkgrayish .h6 {
  color: #FFFFFF; }

.zaux-theme--darkgrayish p,
.zaux-theme--darkgrayish li {
  color: #EFF4F8; }

.zaux-components-loaded .zaux-theme--darkgrayish a {
  transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

.zaux-theme--darkgrayish a:link, .zaux-theme--darkgrayish a:visited {
  color: #CAE1F1; }

.zaux-theme--darkgrayish a:hover, .zaux-theme--darkgrayish a:active {
  color: #FFFFFF;
  text-decoration: none; }

.c-sidebar__panel {
  background-color: #EFF4F8; }

@media (max-width: 1279.98px) {
  .c-accordion {
    border: 2px solid #343b43;
    border-radius: 8px; } }

.c-accordion.highlight {
  border: 2px solid #343b43;
  border-radius: 8px; }

.c-accordion__head--details {
  color: #003567; }

.zaux-theme--light1 .c-nav1 li {
  color: #343b43; }

.zaux-components-loaded .zaux-theme--light1 .c-nav2 a {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }

.zaux-theme--light1 .c-nav2 a:link, .zaux-theme--light1 .c-nav2 a:visited {
  color: #343b43; }

.zaux-theme--light1 .c-nav2 a:hover, .zaux-theme--light1 .c-nav2 a:active {
  color: #003567;
  text-decoration: none; }

.c-nav2__item:hover .c-nav2__item-wrapper, .c-nav2__item.active .c-nav2__item-wrapper {
  background-color: #FFFFFF; }

.c-nav2__item-wrapper:hover .c-nav2__item-description, .c-nav2__item-wrapper:active .c-nav2__item-description {
  color: #343b43;
  text-decoration: none; }

.zaux-components-loaded .c-card1__head.zaux-theme--light1 .c-card1__cats a {
  transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

.c-card1__head.zaux-theme--light1 .c-card1__cats a:link, .c-card1__head.zaux-theme--light1 .c-card1__cats a:visited {
  color: #343b43;
  text-decoration: none; }

.c-card1__head.zaux-theme--light1 .c-card1__cats a:hover, .c-card1__head.zaux-theme--light1 .c-card1__cats a:active {
  color: #343b43;
  text-decoration: none; }

.c-card1__foot.zaux-theme--light1 {
  border-color: #343b43 !important; }

.c-card1__foot.zaux-theme--light2 {
  border-color: #343b43 !important; }
  .c-card1__foot.zaux-theme--light2 a {
    color: #003567; }
  .c-card1__foot.zaux-theme--light2 i {
    color: #343b43; }

.zaux-components-loaded .c-card1__head.zaux-theme--dark1 .c-card1__cats a {
  transition: color 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

.c-card1__head.zaux-theme--dark1 .c-card1__cats a:link, .c-card1__head.zaux-theme--dark1 .c-card1__cats a:visited {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none; }

.c-card1__head.zaux-theme--dark1 .c-card1__cats a:hover, .c-card1__head.zaux-theme--dark1 .c-card1__cats a:active {
  color: #FFFFFF;
  text-decoration: none; }

.c-card1__foot.zaux-theme--dark1 {
  border-color: #FFFFFF !important; }

.c-card1__foot.zaux-theme--dark2 {
  border-color: #FFFFFF !important; }

.zaux-theme--light1 .c-icon {
  color: #343b43; }

.zaux-theme--dark1 .c-icon {
  color: #FFFFFF; }

.zaux-theme--light1 .c-header__inner {
  background-color: #FFFFFF; }

.zaux-theme--light1 .c-header__search-btn .c-icon {
  color: #343b43; }
  @media (min-width: 992px) {
    .zaux-theme--light1 .c-header__search-btn .c-icon {
      color: #999CA0; } }

.c-footer--dark {
  background-color: #333f4c; }

.c-btn1--dark {
  background-color: #343b43;
  color: #FFFFFF !important;
  transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .c-btn1--dark:hover {
    background-color: #003567; }
  .c-btn1--dark:active {
    background-color: #1e2226; }
  .c-btn1--dark--form {
    opacity: 0.5; }

.c-btn1--light {
  background-color: #FFFFFF;
  color: #333333 !important;
  transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .c-btn1--light:hover {
    background-color: #C5D6EF; }
  .c-btn1--light:active {
    background-color: #9dbae4; }

.c-btn1--blue {
  background-color: #C5D6EF;
  color: #333333 !important;
  transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .c-btn1--blue:hover {
    background-color: #EFF4F8; }
  .c-btn1--blue:active {
    background-color: #ccdce8; }

.c-btn1--form {
  opacity: 0.5; }

.c-btn2--light {
  background-color: #EFF4F8;
  color: #003567;
  transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .c-btn2--light:hover {
    background-color: #C5D6EF; }
  .c-btn2--light:active {
    background-color: #9dbae4; }

.c-btn2--white {
  background-color: #FFFFFF;
  color: #003567;
  transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .c-btn2--white:hover {
    background-color: #CAE1F1; }
  .c-btn2--white:active {
    background-color: #a2cae6; }

.c-btn--selected {
  background-color: #343b43;
  color: #FFFFFF; }

.c-form--light input,
.c-form--light textarea,
.c-form--light select {
  border: 2px solid #FFFFFF;
  background-color: transparent; }
  .c-form--light input::placeholder,
  .c-form--light textarea::placeholder,
  .c-form--light select::placeholder {
    opacity: 0.5;
    color: #343b43; }
  .c-form--light input:focus,
  .c-form--light textarea:focus,
  .c-form--light select:focus {
    background-color: transparent; }

.c-form--light .form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23003567' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  background-color: transparent;
  border-color: #003567; }

.c-form--light select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23343b43' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3e %3cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: calc(100% - 20px) center; }

.c-form--light select option {
  color: #343b43; }

.c-form--light .searchbar input {
  border: 2px solid #EFF4F8; }
  .c-form--light .searchbar input:placeholder-shown {
    background-image: url("../../assets/icon/icozaux1/SVG/search.svg");
    opacity: 0.5;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 25px center; }

.c-form--dark label {
  color: #FFFFFF; }

.c-form--dark input:not([type="submit"]) {
  border: 2px solid #C5D6EF;
  background-color: transparent; }
  .c-form--dark input:not([type="submit"])::placeholder {
    opacity: 0.5; }
  .c-form--dark input:not([type="submit"]):focus {
    background-color: transparent; }

.c-form--dark input[type="email"] {
  color: white; }

.c-form--dark .form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23EFF4F8' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  background-color: transparent;
  border-color: #EFF4F8; }

.c-form--dark2 label {
  color: #FFFFFF; }

.c-form--dark2 input {
  border: 2px solid #C5D6EF;
  background-color: transparent; }
  .c-form--dark2 input::placeholder {
    color: #FFFFFF !important;
    opacity: 0.5; }
  .c-form--dark2 input:focus {
    background-color: transparent; }

.c-form--dark2 input[type="email"] {
  color: white; }

.c-form--dark2 input[type="checkbox"] {
  border-color: #C5D6EF !important; }

.c-form--dark2 .form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23EFF4F8' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  background-color: transparent;
  border-color: #EFF4F8; }

.c-pagination--light a {
  background-color: #EFF4F8;
  color: #003567; }
  .c-pagination--light a:hover {
    background-color: #CAE1F1; }

.c-pagination--light .current,
.c-pagination--light .extend {
  background-color: #FFFFFF;
  border: 2px solid #EFF4F8; }
