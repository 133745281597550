@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("header");

$zaux-header-h: 50px;
$zaux-header-lg-h: 70px;

$zaux-sidenav-w: 70px;

.#{$component} {
  position: fixed;
  left: 0;
  top: 0;

  z-index: $zaux-zindex-header;
  transition: all $zaux-transition-1;

  width: 100%;

  &__logo {
    @include media-breakpoint-down(lg) {
      width: 172px;

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;

      > div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  &__inner {
    transition: height $zaux-transition-1;

    height: $zaux-header-h;
    @include media-breakpoint-up(lg) {
      height: $zaux-header-lg-h;
    }
  }

  @include media-breakpoint-up(lg) {
    .ms-lg-6 {
      margin-left: 25px !important;
    }
  }

  &__search {
    [type="search"]::-ms-clear,
    [type="search"]::-ms-reveal,
    [type="search"]::-webkit-search-decoration,
    [type="search"]::-webkit-search-cancel-button,
    [type="search"]::-webkit-search-results-button,
    [type="search"]::-webkit-search-results-decoration {
      display: none !important;
    }

    $w: 150px;

    width: $w;
    position: relative;

    > .c-form {
      opacity: 0.5;
      transition: all 0.6s ease-in-out;

      position: fixed;

      right: 1rem;
      width: 2rem;
      top: 4px;

      @include media-breakpoint-up(lg) {
        top: 12px;
        // right:125px;
        right: 240px;
        // right: 156px;
        // right: 285px;
        width: $w;
      }

      button {
        float: left;
        margin: 0 0 0 -28px;

        transform: translate(40px, 12px);

        @include media-breakpoint-up(lg) {
          transform: translate(40px, 15px);
        }
      }

      .form-control {
        border: 0;
        padding-left: 33px;
        height: 43px;

        font-family: $zaux-font2;
        font-size: 14px;
        line-height: 1;

        outline: none !important;
        box-shadow: none !important;

        @include placeholder() {
          font-family: $zaux-font2;
          font-size: 14px;
          line-height: 1;
        }
      }

      @at-root .zaux-search-active & {
        opacity: 1;
        width: calc(100% - 68px);
        right: 1rem;

        @include media-breakpoint-up(lg) {
          width: calc(100vw - 1rem * 7.3);
        }

        .form-control {
          border: 2px solid $zaux-color-set1-azurewhite;
        }
      }
    }
  }

  &__search-btn {
    font-weight: $zaux-typo-fw-medium;
  }

  @include media-breakpoint-up(lg) {
    &__inner {
      padding-left: $zaux-sidenav-w;
    }
  }
}

// HOTFIXES
.c-fixed-side-nav {
  background-color: $zaux-color-set1-azurewhite !important;
}
