@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("breadcrumbs");

.#{$component} {
  > span, a{
  font-family: $zaux-font1;
  color: $zaux-color-set1-darkblue;
  font-size: getTypoFontsize("6");
  line-height: getTypoLineheight("6");
  @include media-breakpoint-up(lg) {
    font-size: getTypoFontsize("8");
    line-height: getTypoLineheight("8");
  }
  }
}
