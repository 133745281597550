@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("slider");

.#{$component} {
  &__wrapper {
    overflow: hidden;
    position: relative;
  & .swiper-slide {
    height: auto;
  }
  }

  & .swiper-pagination {
    margin: auto;
    position: relative;
    transform: translateY(15px);
    &-bullet{
      width: 10px;
      height: 4px;
      background-color: $zaux-color-set1-beaublue;
      border-radius: 10px;
    }
    &-bullet-active{
      width: 20px;
      background-color: $zaux-color-set1-onyx;
    }
  }
}

