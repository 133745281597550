@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card1");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {
  &__head {
    &.zaux-theme--light1 {
      .#{$component} {
        &__cats {
          a {
            @at-root .#{$zaux-system-classname-components-loaded} & {
              transition: color $zaux-transition-1;
            }

            &:link,
            &:visited {
              // color: rgba($zaux-color-set1-onyx, 0.5);
              color: $zaux-color-set1-onyx;
              text-decoration: none;
            }

            &:hover,
            &:active {
              color: $zaux-color-set1-onyx;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  &__foot {
    &.zaux-theme--light1 {
      border-color: $zaux-color-set1-onyx !important;
    }
    &.zaux-theme--light2 {
      border-color: $zaux-color-set1-onyx !important;
      a {
        color: $zaux-color-set1-darkblue ;
      }
      i {
        color: $zaux-color-set1-onyx;
      }
    }
  }
}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

.#{$component} {
  &__head {
    &.zaux-theme--dark1 {
      .#{$component} {
        &__cats {
          a {
            @at-root .#{$zaux-system-classname-components-loaded} & {
              transition: color $zaux-transition-1;
            }

            &:link,
            &:visited {
              color: rgba($zaux-color-default-white, 0.5);
              text-decoration: none;
            }

            &:hover,
            &:active {
              color: $zaux-color-default-white;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  &__foot {
    &.zaux-theme--dark1 {
      border-color: $zaux-color-default-white !important;
    }
  }
}

////////////////////////////////////////////////////
// Theme: Dark2
////////////////////////////////////////////////////

.#{$component} {
  &__foot {
    &.zaux-theme--dark2 {
      border-color: $zaux-color-default-white !important;
    }
  }
}
