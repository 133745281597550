@import "../../assets/scss/components/base";

$component: componentClass("btn");

.#{$component} {
  &1 {
    &--dark {
      background-color: $zaux-color-set1-onyx;
      color: $zaux-color-default-white !important;
      transition: background-color $zaux-transition-0;
      &:hover {
        background-color: $zaux-color-set1-darkblue;

      }

      &:active {
        background-color: darken(
          $zaux-color-set1-onyx,
          $zaux-active-darken-percentage
        );
      }

      &--form {
        opacity: 0.5;
      }
    }

    &--light {
      background-color: $zaux-color-default-white;
      color: $zaux-color-default-black !important;
      transition: background-color $zaux-transition-0;
      &:hover {
        background-color: $zaux-color-set1-beaublue;
      }
      &:active {
        background-color: darken(
          $zaux-color-set1-beaublue,
          $zaux-active-darken-percentage
        );
      }
    }

    &--blue {
      background-color: $zaux-color-set1-beaublue;
      color: $zaux-color-default-black !important;
      transition: background-color $zaux-transition-0;
      &:hover {
        background-color: $zaux-color-set1-azurewhite;
      }
      &:active {
        background-color: darken(
          $zaux-color-set1-azurewhite,
          $zaux-active-darken-percentage
        );
      }

    }
    &--form {
      opacity: 0.5;
    }
  }

  //button for menu categorie
  &2 {
    &--light {
      background-color: $zaux-color-set1-azurewhite;
      color: $zaux-color-set1-darkblue;
      transition: background-color $zaux-transition-0;
      &:hover {
        background-color: $zaux-color-set1-beaublue;
      }
      &:active {
        background-color: darken(
          $zaux-color-set1-beaublue,
          $zaux-active-darken-percentage
        );
      }
    }
    &--white {

      background-color: $zaux-color-default-white;
      color: $zaux-color-set1-darkblue;
      transition: background-color $zaux-transition-0;
      &:hover {
        // background-color: lighten($zaux-color-app-5, $zaux-hover-lighten-percentage);
        background-color: $zaux-color-set1-azure1;
      }

      &:active {
        background-color: darken(
          $zaux-color-set1-azure1,
          $zaux-active-darken-percentage
        );
      }
    }
  }

  &--selected {
    // background-color: $zaux-color-default-white;
    // border: 2px solid $zaux-color-set1-azurewhite;
    background-color: $zaux-color-set1-onyx;
    color: $zaux-color-default-white;
  }
}
