@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("sidebar");

.#{$component} {
  &__panel {
    background-color: $zaux-color-set1-azurewhite;
  }
}
