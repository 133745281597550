@charset "UTF-8";

@function componentClass($componentName) {
  @return $component-prefix + $componentName;
}

@function rem($size) {
  @return $size / $zaux-font-size-base-px * 1rem;
}

@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@function getSpace($index) {
  @return map-get($spacers, $index);
}
