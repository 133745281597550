@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("footer");

.#{$component} {
  &--dark {
    background-color: $zaux-color-set1-darkgrayish;
    // & a {
    //   color: $zaux-color-set1-azure1;
    // }
  }
}
