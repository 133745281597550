@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("nav1");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {
  @at-root .zaux-theme--light1 & li {
    color: $zaux-color-set1-onyx;
  }
}
