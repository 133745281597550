@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("fx");

.#{$component} {
  &--blend-multiply {
    mix-blend-mode: multiply;
  }
}

.#{$component} {
  &--shadow-onyx-1 {
    box-shadow: 0px 10px 25px rgba($zaux-color-set1-onyx, 0.1);
  }
}
