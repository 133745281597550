@charset "UTF-8";

@import "../../assets/scss/components/base";

$component-name: "sidebar";
$component: componentClass($component-name);

// Desktop
$zaux-zindex-sidebar-lvl-1: $zaux-zindex-sidebar;
$zaux-zindex-sidebar-lvl-2: $zaux-zindex-sidebar - (2 * 2);
$zaux-zindex-sidebar-lvl-3: $zaux-zindex-sidebar - (2 * 3);
$zaux-zindex-sidebar-lvl-4: $zaux-zindex-sidebar - (2 * 4);
$zaux-zindex-sidebar-lvl-5: $zaux-zindex-sidebar - (2 * 5);
$zaux-zindex-sidebar-backdrop: $zaux-zindex-sidebar - (2 * 5) - 1;

// Mobile
$zaux-zindex-sidebar-lvl-1-xs: $zaux-zindex-sidebar;
$zaux-zindex-sidebar-lvl-2-xs: $zaux-zindex-sidebar + (2 * 2);
$zaux-zindex-sidebar-lvl-3-xs: $zaux-zindex-sidebar + (2 * 3);
$zaux-zindex-sidebar-lvl-4-xs: $zaux-zindex-sidebar + (2 * 4);
$zaux-zindex-sidebar-lvl-5-xs: $zaux-zindex-sidebar + (2 * 5);

$zaux-sidebar-panel-top-h: 50px;
$zaux-sidebar-panel-top-lg-h: 70px;

$zaux-sidebar-panel-layout1-foot-h: 1rem * 5;
$zaux-sidebar-panel-layout2-foot-h: 1rem * 9;
$zaux-sidebar-panel-side-w: 52px;

$zaux-sidebar-logo-mobile-w: 172px;

.zaux-sidebar-active {
  overflow: hidden;
}

.zaux-show-on-#{$component-name}-active {
  @include media-breakpoint-up(lg) {
    transition: opacity $zaux-transition-0;
    opacity: 0;
    @at-root .zaux-sidebar-active & {
      opacity: 1;
    }
  }
}

.#{$component} {
  [class*="__btn-"] {
    padding: 0;
  }

  .px-2 {
    padding-left: 14px !important;
    padding-right: 8px !important;
    @include media-breakpoint-up(lg) {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  & .c-sidebar__panel-foot .c-btn1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &,
  &__panel,
  &__panel-inner,
  &__panel-head,
  &__panel-content {
    height: 100%;
  }

  &__breadcrumb {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-wrapper {
      height: 100%;
    }
  }

  &__panel-side {
    @include media-breakpoint-down(lg) {
      border-right: 2px solid $zaux-color-default-white;

      position: absolute;
      top: 50px;
      left: 0;
      width: $zaux-sidebar-panel-side-w;
      overflow: hidden;
      height: calc(100% - #{$zaux-sidebar-panel-top-h});
      z-index: 1;

      // padding-top: 6px;
      padding-top: 1rem;
      padding-left: 5px;

      ~ .#{$component}__panel-head .#{$component}__panel-content,
      ~ .#{$component}__panel-foot {
        margin-left: $zaux-sidebar-panel-side-w;
      }
    }
  }

  &__logo {
    width: $zaux-sidebar-logo-mobile-w;

    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }

    @include media-breakpoint-down(lg) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
    }
  }

  &__panel-top {
    position: relative;
  }

  &__panel-content {
    overflow-y: auto;
    @include hideScrollbars();
  }

  &__panel-foot {
    order: 2;
  }

  &__btn-prev,
  &__btn-toggle {
    @include media-breakpoint-down(lg) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      line-height: 0;
    }
  }

  &__btn-toggle {
    @include media-breakpoint-up(lg) {
      transform: translateY(-3px);
    }
  }

  &__panel {
    position: fixed;
    left: 0;
    top: 0;

    overflow: hidden;

    transition: width $zaux-transition-0, opacity $zaux-transition-0,
      transform $zaux-transition-0;

    @include media-breakpoint-down(lg) {
      z-index: $zaux-zindex-sidebar-lvl-1;
      transform: translateX(100%);
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      z-index: -1;
      opacity: 0;
    }

    &--layout1 {
      .#{$component} {
        &__panel-top {
          height: $zaux-sidebar-panel-top-h;
        }
        &__panel-head {
          height: 100%;
          order:2;
          @include media-breakpoint-down(lg){
            order:1;
          }

        }
        &__panel-content {
          height: calc(100% - #{$zaux-sidebar-panel-top-h});

          @include media-breakpoint-down(lg) {
            padding-top: 10px;
          }

          @include media-breakpoint-up(lg) {
            height: 100%;
            // &-inner {
            //   padding-top: $zaux-sidebar-panel-top-lg-h;
            // }
          }
        }

        &__panel-foot {
          height: $zaux-sidebar-panel-layout1-foot-h;
            order: 1;
            margin-top: 70px;
            @include media-breakpoint-down(lg) {
            order: 2;
            margin-top: 0;
            }
        }

        &__panel-foot + .#{$component}__panel-head {
          height: calc(100% - #{$zaux-sidebar-panel-layout1-foot-h});
        }
      }
    }

    &--layout2 {
      .#{$component} {
        &__panel-top {
          height: $zaux-sidebar-panel-top-h;
          @include media-breakpoint-up(lg) {
            height: $zaux-sidebar-panel-top-lg-h;
          }
        }
        &__panel-head {
          height: 100%;
        }
        &__panel-content {
          height: calc(100% - #{$zaux-sidebar-panel-top-h});

          @include media-breakpoint-down(lg) {
            padding-top: 10px;
          }
          @include media-breakpoint-up(lg) {
            height: 100%;
          }
        }
        &__panel-foot {
          @include media-breakpoint-down(lg) {
            display: none;
          }
          // height: $zaux-sidebar-panel-layout2-foot-h;
          height: 10rem;
        }
        &__panel-foot + .#{$component}__panel-head {
          @include media-breakpoint-up(lg) {
            // height: calc(100% - #{$zaux-sidebar-panel-layout2-foot-h});
            height: calc(100% - 10rem);
          }
        }
      }
    }

    &[data-level="1"] {
      @include media-breakpoint-up(lg) {
        width: $zaux-sidebar-w;
        z-index: $zaux-zindex-sidebar-lvl-1;
        opacity: 1;
      }
    }

    &.open {
      @include media-breakpoint-up(lg) {
        z-index: 999 !important;
      }
    }

    &.open,
    &.opening {
      @include media-breakpoint-down(lg) {
        transform: translateX(0);
      }

      @include media-breakpoint-up(lg) {
        transform: translateX(100%);
        opacity: 1;
      }
    }

    &[data-level="1"] {
      @include media-breakpoint-down(lg) {
        @at-root .zaux-sidebar-active & {
          transform: translateX(0);
        }
      }
    }

    &[data-level="2"] {
      @include media-breakpoint-down(lg) {
        &.open,
        &.opening,
        &.closing {
          z-index: $zaux-zindex-sidebar-lvl-2-xs;
        }
      }
      @include media-breakpoint-up(lg) {
        left: 0;
        &.open,
        &.opening,
        &.closing {
          z-index: $zaux-zindex-sidebar-lvl-2;
        }
      }
    }

    &[data-level="3"] {
      @include media-breakpoint-down(lg) {
        &.open,
        &.opening,
        &.closing {
          z-index: $zaux-zindex-sidebar-lvl-3-xs;
        }
      }
      @include media-breakpoint-up(lg) {
        left: $zaux-sidebar-active-w * 1;
        &.open,
        &.opening,
        &.closing {
          z-index: $zaux-zindex-sidebar-lvl-3;
        }
      }
    }

    &[data-level="4"] {
      @include media-breakpoint-down(lg) {
        &.open,
        &.opening,
        &.closing {
          z-index: $zaux-zindex-sidebar-lvl-4-xs;
        }
      }
      @include media-breakpoint-up(lg) {
        left: $zaux-sidebar-active-w * 2;
        &.open,
        &.opening,
        &.closing {
          z-index: $zaux-zindex-sidebar-lvl-4;
        }
      }
    }

    &[data-level="5"] {
      @include media-breakpoint-down(lg) {
        &.open,
        &.opening,
        &.closing {
          z-index: $zaux-zindex-sidebar-lvl-5-xs;
        }
      }
      @include media-breakpoint-up(lg) {
        left: $zaux-sidebar-active-w * 3;
        &.open,
        &.opening,
        &.closing {
          z-index: $zaux-zindex-sidebar-lvl-5;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      @at-root .zaux-#{$component-name}-active & {
        width: $zaux-sidebar-active-w;
      }
    }

    &-inner {
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      @include media-breakpoint-up(lg) {
        width: $zaux-sidebar-active-w;
        &:before {
          content: " ";
          display: block;

          position: absolute;
          left: 0;
          bottom: 0;

          width: 1px;

          height: calc(100vh - #{$zaux-sidebar-panel-top-lg-h});
          border-left: 2px solid $zaux-color-default-white;

          z-index: 999;
        }
      }
    }
  }

  &__backdrop {
    @include media-breakpoint-up(lg) {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: $zaux-zindex-sidebar-backdrop;
      display: none;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .c-nav2__item-wrapper {
    &::after {
      opacity: 0;
      @at-root .zaux-sidebar-active & {
        opacity: 1;
      }
    }
  }

  .c-btn1 {
    max-width: none;
  }
}

.#{$component} {
  @at-root .debug & {
    .zaux-show-on-#{$component-name}-active {
      opacity: 1 !important;
    }
    &__panel[data-level] {
      width: $zaux-sidebar-active-w !important;
      transform: none !important;
    }
    #sidebar-panel-1 {
      z-index: 9999;
      opacity: 123;
      left: 0;
    }
    #sidebar-panel-2 {
      z-index: 9999;
      opacity: 123;
      left: $zaux-sidebar-active-w * 1;
    }
    #sidebar-panel-3 {
      z-index: 9999;
      opacity: 123;
      left: $zaux-sidebar-active-w * 2;
    }
  }
}
