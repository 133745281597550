@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("nav1");

[class*="#{$component}"] {
  ul {
    list-style: none;
    display: flex;
  }
}

.#{$component} {
  &--layout1 {
    ul {
      align-items: center;
    }
    li {
      font-family: $zaux-font2;
      font-size: getTypoFontsize("3");
      line-height: getTypoLineheight("3");

      &:not(:last-child) {
        margin-right: getSpace("6");
      }
    }
  }
  &--layout2 {
    ul {
      align-items: center;
    }
    li {
      &:not(:last-child) {
        margin-right: getSpace("4");
      }
    }
  }
  &--layout3 {
    ul {
      display: block;
    }
    li {
      &:not(:last-child) {
        margin-bottom: getSpace("4");
      }
    }
  }
  &--fh {
    ul {
      height: 100%;
    }
  }
}
