@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("typo");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--light1 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  li {
    color: $zaux-color-set1-onyx;
  }

  a {
    @at-root .#{$zaux-system-classname-components-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: $zaux-color-set1-darkblue;
    }

    &:hover,
    &:active {
      color: $zaux-color-set1-darkblue;
      // text-decoration: underline;
      text-decoration: none;
    }
  }
}

////////////////////////////////////////////////////
// Theme: Light2
////////////////////////////////////////////////////

.zaux-theme--light2 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  li {
    color: $zaux-color-set1-onyx;
    list-style-type: none;
  }
  ul {
    padding-left: 0;
  }

  a {
    color: $zaux-color-set1-darkblue;
    @at-root .#{$zaux-system-classname-components-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: $zaux-color-set1-darkblue;
    }

    &:hover,
    &:active {
      // color: $zaux-color-set1-onyx;
      text-decoration: underline;
    }
  }
}

////////////////////////////////////////////////////
// Theme: Light3
////////////////////////////////////////////////////

.zaux-theme--light3 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  li {
    color: $zaux-color-set1-onyx;
  }

  a {
    color: $zaux-color-set1-onyx;
    @at-root .#{$zaux-system-classname-components-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: $zaux-color-set1-onyx;
    }

    &:hover,
    &:active {
      color: $zaux-color-set1-onyx;
      text-decoration: none;
    }
  }
}

.zaux-theme--light4 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  li {
    color: $zaux-color-set1-darkblue;
  }

  a {
    color: $zaux-color-set1-darkblue;
    @at-root .#{$zaux-system-classname-components-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: $zaux-color-set1-darkblue;
    }

    &:hover,
    &:active {
      color: $zaux-color-set1-onyx;
      text-decoration: none;
    }
  }
}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

.zaux-theme--dark1 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: $zaux-color-default-white;
  }

  p,
  li {
    color: $zaux-color-set1-azurewhite;
  }

  a {
    @at-root .#{$zaux-system-classname-components-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: $zaux-color-set1-azurewhite;
    }

    &:hover,
    &:active {
      color: $zaux-color-set1-azurewhite;
      text-decoration: underline;
    }
  }
}

////////////////////////////////////////////////////
// Theme: Dark2
////////////////////////////////////////////////////

.zaux-theme--dark2 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: $zaux-color-default-white;
  }

  p,
  li {
    color: $zaux-color-set1-azurewhite;
  }

  a {
    @at-root .#{$zaux-system-classname-components-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: $zaux-color-set1-azure1;
    }

    &:hover,
    &:active {
      color: $zaux-color-default-white;
      text-decoration: none;
    }
  }
}

////////////////////////////////////////////////////
// Theme: Dark3 grayish
////////////////////////////////////////////////////

.zaux-theme--darkgrayish {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: $zaux-color-default-white;
  }

  p,
  li {
    color: $zaux-color-set1-azurewhite;
  }

  a {
    @at-root .#{$zaux-system-classname-components-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: $zaux-color-set1-azure1;
    }

    &:hover,
    &:active {
      color: $zaux-color-default-white;
      text-decoration: none;
    }
  }
}
